import "./TextField.css";
import React from "react";
import { useState } from "react";
import { useRef } from "react";

const TextField = (props) => {
  const inputRef = useRef(null);
  const disableButton = () => {
    if (
      !props.LastMessage.question ||
      // props.LastMessage.content == "Hey! This is Alan from Golden Recovery!" ||
      props.Stage == "complete" ||
      props.Stage == "Great. Your case was sent to one of our Scam Advisors." ||
      props.Stage == "readMore" ||
      (props.SelectAnswer == true &&
        props.Stage !=
          "Let's go over the details of your case and figure out how we can help you. Shall we?")
    ) {
      return true;
    } else {
      return false;
    }
  };
  const scrollToInput = () => {
    inputRef.current.scrollIntoView({ behavior: "smooth" });
  };
  const [NewMessage, setNewMessage] = useState("");
  function sendMessage() {
    if (NewMessage != "") {
      let newArr = props.Messages;
      let newMessage = {
        question: false,
        content: NewMessage,
        date: new Date(),
      };
      newArr.push(newMessage);

      props.setMessages([...newArr]);
      setNewMessage("");
    }
  }
  return (
    <div className="text-field" ref={inputRef}>
      <input
        // onKeyDown={(e) => e.key === "Enter" && handle}
        onKeyDown={(e) => {
          if (e.key == "Enter") {
            if (!disableButton()) {
              sendMessage();
            }
          }
        }}
        placeholder="Message"
        type={
          props.Stage == "enterPhone" || props.Stage == "enterPhone2"
            ? "number"
            : "text"
        }
        value={NewMessage}
        onChange={(e) => {
          scrollToInput();
          setNewMessage(e.target.value);
        }}
      ></input>
      <button
        disabled={disableButton()}
        onClick={() => {
          sendMessage();
        }}
        aria-label="Send Message"
      >
        <i className="fas fa-paper-plane"></i>
      </button>
    </div>
  );
};

export default TextField;
