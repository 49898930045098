const Answers = {

    hello: [
        "Hey! This is Alan from Golden Recovery!",
        "Hi! I'm Alan from Golden Recovery!",
        "I am Alan from Golden Recovery, nice to meet you!",
        "Hey! My name is Alan, Golden Recovery's online agent",
    ],
    start: [
        "Let's go over the details of your case and figure out how we can help you. Shall we?",
        "I'm going to ask you a few questions about your case. Ready to start?",

    ],

    positiveWord: [
        "Great",
        "Awesome",
        "Excellent",
        "Good",
        "Perfect",
    ],
    enterName: [
        "What's your name?",
        "Please enter your name",

    ],
    incorrectName: [
        "Oops! it seems you entered incorrect details",
        "Please enter a full name and refrain from using special characters",

    ],
    enterPhone: [
        "What's your phone number?",
    ],
    shortPhone: [
        "The number you entered is too short",
        "Oops! it seems the number you entered is too short"
    ],
    longPhone: [
        "The number you entered is too long",
        "Oops! it seems the number you entered is too long"
    ],
    enterCountry: [
        "What country are you from?",
        "Please enter your country",

    ],
    incorrectCountry: [
        "Sorry, this is not a registered country",
        "Oops! it seems the country you typed is not registered...",

    ],

}

export default Answers