import "./MainView.css";
import React from "react";
import Message from "../Message/Message";
import TextField from "../TextField/TextField";
import { useEffect } from "react";
import { useState } from "react";
import { useRef } from "react";
import Countries from "../../Countries";
import greetings from "../../media/img/greetings.gif";
import hello from "../../media/img/hello.gif";
import skull from "../../media/img/skull.svg";
import TypeWriterEffect from "react-typewriter-effect";
import Answers from "../../Answers";
import { useBeforeunload } from "react-beforeunload";
import DeviceDetector from "device-detector-js";
import ContactInfo from "../ContactInfo/ContactInfo";
import moment from "moment";

const MainView = (props) => {
  const [ClientName, setClientName] = useState("");
  const [ClientMail, setClientMail] = useState("");
  const [ClientPhone, setClientPhone] = useState("");
  const [ClientCountry, setClientCountry] = useState("");
  const [ClientLostAmount, setClientLostAmount] = useState("");
  const [ScamDate, setScamDate] = useState("");
  const [ScamCompany, setScamCompany] = useState("");
  const [TransferMethod, setTransferMethod] = useState("");
  const [CommunicationMethod, setCommunicationMethod] = useState("");
  const [CaseDescription, setCaseDescription] = useState("");
  const [AdditionalDetails, setAdditionalDetails] = useState("");
  const [SelectAnswer, setSelectAnswer] = useState(false);
  const [AnswerButtons, setAnswerButtons] = useState([]);
  const [Hotlinks, setHotlinks] = useState(false);
  const messagesEndRef = useRef(null);
  const [Stage, setStage] = useState("Hey! This is Alan from Golden Recovery!");
  // const [Stage, setStage] = useState("complete");
  const [CloseScreen, setCloseScreen] = useState(false);
  const [CorrectedCountry, setCorrectedCountry] = useState("");
  const [UserAgent, setUserAgent] = useState();
  const deviceDetector = new DeviceDetector();
  const [Messages, setMessages] = useState([
    {
      question: true,
      content: randomAnswer(Answers.hello),
      date: new Date(),
    },
  ]);
  useEffect(() => {
    let agent = window.navigator.userAgent;
    const device = deviceDetector.parse(agent);
    setUserAgent(device);
  }, []);
  const LastMessage = Messages[Messages.length - 1];
  const LastQuestion = Messages.filter((msg) => msg.question)[
    Messages.filter((msg) => msg.question).length - 1
  ];
  const LastAnswer = Messages.filter((msg) => !msg.question)[
    Messages.filter((msg) => !msg.question).length - 1
  ];
  const scrollToBottom = () => {
    messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
  };
  const form = {
    name: ClientName,
    email: ClientMail,
    phone: ClientPhone,
    country: ClientCountry,
    amountLost: ClientLostAmount,
    scamDate: ScamDate,
    scamCompany: ScamCompany,
    transferMethod: TransferMethod,
    communicationMethod: CommunicationMethod,
    caseDescription: CaseDescription,
    additionalDetails: AdditionalDetails,
    device: UserAgent,
  };
  const gifs = [greetings, hello];

  function randomAnswer(answers) {
    return answers[Math.floor(Math.random() * answers.length)];
  }
  function sendLogs() {
    let arr = [];
    Messages.forEach((message) => {
      // arr.push({
      //   from: message.question ? "Alan" : "Client",
      //   content: message.content,
      //   date: moment(message.date).format("DD/MM/YYYY - HH:mm"),
      // });
      // string.concat(
      //   message.question ? "Alan:" : "Client:",
      //   "\n",
      //   message.content,
      //   "\n",
      //   moment(message.date).format("DD/MM/YYYY - HH:mm"),
      //   "\n" + "\n"
      // );
      arr.push(
        message.question ? "Alan:" : "Client:",
        "\n",
        message.content,
        "\n",
        moment(message.date).format("DD/MM/YYYY - HH:mm"),
        "\n",
        "\n"
      );
    });
    arr.join("");
    // console.log(String(arr));
    let url = "https://hooks.zapier.com/hooks/catch/11547837/bzbk32c";
    let requestOptions = {
      method: "POST",
      headers: {},
      body: JSON.stringify({ title: ClientMail, logs: String(arr) }),
    };
    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((json) => {
        // console.log(json);
      });
  }
  function sendForm() {
    let url = "https://hooks.zapier.com/hooks/catch/11547837/b8lu40s/";
    let requestOptions = {
      method: "POST",
      headers: {},
      body: JSON.stringify(form),
    };
    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((json) => {
        // console.log(json);
      });
  }
  function botMessage(msg) {
    let newArr = Messages;
    newArr.push({
      question: true,
      content: msg,
      date: new Date(),
    });
    setMessages([...newArr]);
  }
  function checkCountry(input) {
    let flag = false;
    Countries.forEach((country) => {
      if (
        country.common.toLowerCase() == input.toLowerCase() ||
        country.official.toLowerCase() == input.toLowerCase()
      ) {
        flag = true;
      }
    });

    return flag;
  }

  function includeCountry(input) {
    let flag = false;
    Countries.forEach((country) => {
      if (
        country.common.toLowerCase().includes(input.toLowerCase()) ||
        country.official.toLowerCase().includes(input.toLowerCase())
      ) {
        flag = true;
      }
    });

    return flag;
  }
  function correctCountry(input) {
    let country = Countries.filter(
      (country) =>
        country.common.toLowerCase().includes(input.toLowerCase()) ||
        country.official.toLowerCase().includes(input.toLowerCase())
    )[0].common;
    setCorrectedCountry(country);
    return country;
  }
  useEffect(() => {
    let arr = Messages;
    if (Messages.length == 1) {
      arr.push({
        question: true,
        content: (
          <img
            className="greetings-gif"
            alt="Greetings"
            width={100}
            height={100}
            src={props.Error ? skull : randomAnswer(gifs)}
          />
        ),
        date: new Date(),
      });
      setTimeout(() => {
        setStage("greetings");
        setMessages([...arr]);
      }, 1000 * Math.ceil(Math.random() * 1));
    }
  }, []);

  useEffect(() => {
    // LastAnswer && console.log(fixCountryName(LastAnswer.content));
    if (!LastMessage.question) {
      setSelectAnswer(false);
    }
  }, [Messages]);
  useEffect(() => {
    if (Stage === "greetings") {
      setTimeout(() => {
        setSelectAnswer(true);
        setAnswerButtons(["Yes", "No"]);
        setStage(
          "Let's go over the details of your case and figure out how we can help you. Shall we?"
        );
        botMessage(randomAnswer(Answers.start));
      }, 1000 * Math.ceil(Math.random() * 4));
    }
  }, [Messages]);
  function botAnswers() {
    let errorMsg = "Something went wrong...";
    // let nameRegex = /^[a-zA-Z\s]*$/;
    let nameRegex = /^((\b[a-zA-Z]{2,40}\b)\s*){2,}$/;
    let mailRegex =
      /[a-zA-Z0-9.%+-]{1,64}\@{1}[a-zA-Z0-9.%+-]{1,64}\.{1}[a-zA-Z]{2,32}$/;
    // let phoneRegex = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/g;
    let phoneRegex = /\d+/;
    switch (Stage) {
      case "Let's go over the details of your case and figure out how we can help you. Shall we?":
        if (LastAnswer.content.toLowerCase().includes("nir is gay")) {
          return "Truth be told brother";
        } else if (LastAnswer.content === "goHome()") {
          window.location.href = "https://www.golden-recovery.com";
          return "";
        } else if (LastAnswer.content === "selfDestruct()") {
          setStage("SELF DESTRUCTION");
          return (
            <code className="destruction-message">
              INITIALIZING SELF DESTRUCTION
            </code>
          );
        } else {
          if (
            LastAnswer.content.toLowerCase().includes("yes") ||
            LastAnswer.content.toLowerCase().includes("ok") ||
            LastAnswer.content.toLowerCase().includes("yea")
          ) {
            setSelectAnswer(false);
            setStage("What's your full name?");
            return `${randomAnswer(Answers.positiveWord)}!
                    What's your full name?`;
          } else {
            if (LastAnswer.content.toLowerCase().includes("no")) {
              setStage("Sorry to see you go");
              return "Sorry to see you go😔";
            } else {
              return errorMsg;
            }
          }
        }
      case "What's your full name?":
        setSelectAnswer(false);
        if (LastAnswer.content.toLowerCase().includes("my nam")) {
          return "Please enter just your name";
        }
        if (nameRegex.test(LastAnswer.content)) {
          botMessage(`Nice to meet you ${LastAnswer.content}!
          In case our conversation is interrupted, please fill in your contact information, and we'll be able to get back to you with a Scam Advisor.`);

          setClientName(LastAnswer.content);
          setStage("What's your Email Address?");
          return `What's your email address?`;
        } else {
          return "Please enter a full name and refrain from using special characters";
        }
      case "What's your Email Address?":
        if (mailRegex.test(LastAnswer.content)) {
          setClientMail(LastAnswer.content);
          setStage("enterPhone");
          return "And what's your phone number?";
        } else {
          return "Please enter a valid email address (Example: example@mail.com)";
        }
      case "enterPhone":
        if (phoneRegex.test(LastAnswer.content)) {
          if (LastAnswer.content.length > 10) {
            if (LastAnswer.content.length < 20) {
              setClientPhone(LastAnswer.content);
              setStage("Got it. Which country are you from?");
              return "Got it. Which country are you from?";
            } else {
              return randomAnswer(Answers.longPhone);
            }
          } else {
            return randomAnswer(Answers.shortPhone);
          }
        } else {
          return "Please type an international phone number";
        }
      case "Got it. Which country are you from?":
        if (LastAnswer.content != "") {
          if (
            checkCountry(LastAnswer.content) ||
            LastAnswer.content.toLowerCase().includes("usa") ||
            LastAnswer.content.toLowerCase().includes("uae")
          ) {
            setClientCountry(LastAnswer.content);
            setStage("Great. So let's recap your information, one last time.");
            setAnswerButtons(["Yes", "No"]);
            setSelectAnswer(true);
            return `${randomAnswer(
              Answers.positiveWord
            )}. So let's recap your information, one last time.
                    Name: ${ClientName}
                    Email: ${ClientMail}
                    Phone: ${ClientPhone}
                    Country: ${LastAnswer.content}
                    Is everything correct?`;
          } else {
            if (includeCountry(LastAnswer.content)) {
              setStage("correctCountry");
              setAnswerButtons(["Yes", "No"]);
              setSelectAnswer(true);
              return `Did you mean ${correctCountry(LastAnswer.content)}?`;
            } else {
              return randomAnswer(Answers.incorrectCountry);
            }
          }
        } else {
          return errorMsg;
        }
      case "correctCountry":
        if (LastAnswer.content == "Yes") {
          setClientCountry(CorrectedCountry);
          setStage("Great. So let's recap your information, one last time.");
          setAnswerButtons(["Yes", "No"]);
          setSelectAnswer(true);
          return `${randomAnswer(
            Answers.positiveWord
          )}. So let's recap your information, one last time.
                    Name: ${ClientName}
                    Email: ${ClientMail}
                    Phone: ${ClientPhone}
                    Country: ${CorrectedCountry}
                    Is everything correct?`;
        } else {
          if (LastAnswer.content == "No") {
            setStage("Got it. Which country are you from?");
            return randomAnswer(Answers.enterCountry);
          } else {
            return errorMsg;
          }
        }
      case "correctCountry2":
        if (LastAnswer.content == "Yes") {
          setClientCountry(CorrectedCountry);
          setStage("Great. So let's recap your information, one last time.");
          setAnswerButtons(["Yes", "No"]);
          setSelectAnswer(true);
          return `${randomAnswer(
            Answers.positiveWord
          )}. So let's recap your information, one last time.
                    Name: ${ClientName}
                    Email: ${ClientMail}
                    Phone: ${ClientPhone}
                    Country: ${CorrectedCountry}
                    Is everything correct?`;
        } else {
          if (LastAnswer.content == "No") {
            setStage("Got it. Which country are you from?");
            return randomAnswer(Answers.enterCountry);
          } else {
            return errorMsg;
          }
        }
      case "Great. So let's recap your information, one last time.":
        if (
          LastAnswer.content.toLowerCase().includes("yes") ||
          LastAnswer.content.toLowerCase().includes("yea")
        ) {
          setSelectAnswer(true);
          setAnswerButtons(["0-5K", "5-15K", "15-30K", "30K+"]);
          setStage("How much were you scammed for? (in USD)");
          return "How much were you scammed for? (in USD)";
        } else {
          if (LastAnswer.content.toLowerCase().includes("no")) {
            setStage("What's wrong?");
            setSelectAnswer(true);
            setAnswerButtons(["Name", "Email", "Phone", "Country"]);
            return "What's wrong?";
          } else {
            return errorMsg;
          }
        }
      case "What's wrong?":
        if (LastAnswer.content.toLowerCase().includes("name")) {
          setStage("Please enter your name");
          return "Please enter your name";
        }
        if (LastAnswer.content.toLowerCase().includes("mail")) {
          setStage("Please enter your Email");
          return "Please enter your Email";
        }
        if (LastAnswer.content.toLowerCase().includes("phone")) {
          setStage("enterPhone2");
          return "Please enter your number";
        }
        if (LastAnswer.content.toLowerCase().includes("country")) {
          setStage("Please enter your country");
          return randomAnswer(Answers.enterCountry);
        }
        if (
          !LastAnswer.content.toLowerCase().includes("name") &&
          !LastAnswer.content.toLowerCase().includes("mail") &&
          !LastAnswer.content.toLowerCase().includes("phone") &&
          !LastAnswer.content.toLowerCase().includes("country")
        ) {
          return errorMsg;
        }
      case "Please enter your name":
        if (nameRegex.test(LastAnswer.content)) {
          setClientName(LastAnswer.content);
          setSelectAnswer(true);
          setAnswerButtons(["Yes", "No"]);
          setStage("Anything else?");

          return "Anything else?";
        } else {
          return "Please enter a full name";
        }
      case "Please enter your Email":
        if (mailRegex.test(LastAnswer.content)) {
          setClientMail(LastAnswer.content);
          setSelectAnswer(true);
          setAnswerButtons(["Yes", "No"]);
          setStage("Anything else?");
          return "Anything else?";
        } else {
          return "Please enter a valid email address (Example: example@mail.com)";
        }
      case "enterPhone2":
        if (phoneRegex.test(LastAnswer.content)) {
          setClientPhone(LastAnswer.content);
          setSelectAnswer(true);
          setAnswerButtons(["Yes", "No"]);
          setStage("Anything else?");
          return "Anything else?";
        } else {
          return "Please type an international phone number";
        }
      case "Please enter your country":
        if (LastAnswer.content) {
          if (
            checkCountry(LastAnswer.content) ||
            LastAnswer.content.toLowerCase().includes("usa") ||
            LastAnswer.content.toLowerCase().includes("uae")
          ) {
            setClientCountry(LastAnswer.content);
            setStage("Anything else?");
            setSelectAnswer(true);
            setAnswerButtons(["Yes", "No"]);
            return "Anything else?";
          } else {
            if (includeCountry(LastAnswer.content)) {
              setStage("correctCountry2");
              setAnswerButtons(["Yes", "No"]);
              setSelectAnswer(true);
              return `Did you mean ${correctCountry(LastAnswer.content)}`;
            } else {
              return randomAnswer(Answers.incorrectCountry);
            }
          }
        } else {
          return errorMsg;
        }
      case "Anything else?":
        if (LastAnswer.content.toLowerCase().includes("name")) {
          setSelectAnswer(false);
          setStage("Please enter your name");
          return "Please enter your name";
        }
        if (LastAnswer.content.toLowerCase().includes("mail")) {
          setSelectAnswer(false);
          setStage("Please enter your Email");
          return "Please enter your Email";
        }
        if (LastAnswer.content.toLowerCase().includes("phone")) {
          setSelectAnswer(false);
          setStage("enterPhone2");
          return "Please enter your number";
        }
        if (LastAnswer.content.toLowerCase().includes("country")) {
          setSelectAnswer(false);
          setStage("Please enter your country");
          return randomAnswer(Answers.enterCountry);
        }
        if (
          LastAnswer.content.toLowerCase().includes("yes") ||
          LastAnswer.content.toLowerCase().includes("yea")
        ) {
          setSelectAnswer(true);
          setAnswerButtons(["Name", "Email", "Phone", "Country"]);
          return "What details would you like to change?";
        }
        if (LastAnswer.content.toLowerCase().includes("no")) {
          setClientCountry(LastAnswer.content);
          setSelectAnswer(true);
          setAnswerButtons(["Yes", "No"]);
          setStage("Great. So let's recap your information, one last time.");
          return `${randomAnswer(
            Answers.positiveWord
          )}. So let's recap your information, one last time.
          Name: ${ClientName}
          Email: ${ClientMail}
          Phone: ${ClientPhone}
          Country: ${ClientCountry}
          Is everything correct?`;
        }
      case "How much were you scammed for? (in USD)":
        if (LastAnswer.content != "") {
          setClientLostAmount(LastAnswer.content);
          setStage("And when did it happen?");
          setSelectAnswer(true);
          setAnswerButtons([
            "Last Six Months",
            "6 - 12 Months",
            "Year - 2 Years",
            "2 - 3 Years",
            "3+ Years",
          ]);
          return "And when did it happen?";
        } else {
          return errorMsg;
        }
      case "And when did it happen?":
        if (LastAnswer.content != "") {
          setScamDate(LastAnswer.content);
          setSelectAnswer(true);
          setAnswerButtons(["Yes", "No"]);
          setStage("Do you remember the company's name?");
          return "Do you remember the company's name?";
        } else {
          return errorMsg;
        }
      case "Do you remember the company's name?":
        if (
          LastAnswer.content.toLowerCase().includes("yes") ||
          LastAnswer.content.toLowerCase().includes("yea")
        ) {
          setSelectAnswer(false);
          setStage("Please write it down");
          return "Please write it down";
        } else {
          if (LastAnswer.content.toLowerCase().includes("no")) {
            setScamCompany("Don't remember");
            setSelectAnswer(true);
            setAnswerButtons([
              "Credit/Debit",
              "Wire Transfer",
              "Crypto",
              "Other",
            ]);
            setStage("How did you transfer the money?");
            return "How did you transfer the money?";
          }
        }
      case "Please write it down":
        if (LastAnswer.content != "") {
          setScamCompany(LastAnswer.content);
          setStage("How did you transfer the money?");
          setSelectAnswer(true);
          setAnswerButtons([
            "Credit/Debit",
            "Wire Transfer",
            "Crypto",
            "Other",
          ]);
          return "How did you transfer the money?";
        }
      case "How did you transfer the money?":
        if (LastAnswer.content != "Other") {
          setTransferMethod(LastAnswer.content);
          setStage("What method of communication did the scammer use?");
          return "What method of communication did the scammer use?";
        } else if (LastAnswer.content == "Other") {
          setStage("transferMoneyOther");
          return "Then how?";
        } else {
          return errorMsg;
        }
      case "transferMoneyOther":
        if (LastAnswer.content != "") {
          setTransferMethod(LastAnswer.content);
          setStage("What method of communication did the scammer use?");
          return "What method of communication did the scammer use?";
        } else {
          return errorMsg;
        }
      case "What method of communication did the scammer use?":
        if (LastAnswer.content != "") {
          setCommunicationMethod(LastAnswer.content);
          setStage("Let us know what happened to you");
          setSelectAnswer(true);
          setAnswerButtons([
            "Binary Scam",
            "Crypto Scam",
            "Forex Scam",
            "Nigerian Scam",
            "Stock Trading",
            "Online Banking",
            "Other",
          ]);
          return "Let us know what happened to you";
        } else {
          return errorMsg;
        }
      case "Let us know what happened to you":
        setSelectAnswer(false);
        setAnswerButtons([""]);
        if (LastAnswer.content != "") {
          setCaseDescription(LastAnswer.content);
          if (LastAnswer.content == "Other") {
            setStage("caseOther");
            return "Tell us more about your case";
          } else {
            setStage("Is there anything else you'd like to add?");
            setSelectAnswer(true);
            setAnswerButtons(["Yes", "No"]);
            return "Is there anything else you'd like to add?";
          }
        } else {
          return errorMsg;
        }
      case "caseOther":
        if (LastAnswer.content != "") {
          setCaseDescription(LastAnswer.content);
          setStage("Is there anything else you'd like to add?");
          setSelectAnswer(true);
          setAnswerButtons(["Yes", "No"]);
          return "Is there anything else you'd like to add?";
        } else {
          return errorMsg;
        }

      case "Is there anything else you'd like to add?":
        if (
          LastAnswer.content.toLowerCase().includes("yes") ||
          LastAnswer.content.toLowerCase().includes("yea")
        ) {
          setStage("additionalDetails");
          return "Tell us more about your case";
        } else {
          if (LastAnswer.content.toLowerCase().includes("no")) {
            setAdditionalDetails("No additional details");
            setStage("complete");
            return "Preparing your online case...";
          }
        }
      case "additionalDetails":
        if (LastAnswer.content != "") {
          setAdditionalDetails(LastAnswer.content);
          setStage("complete");
          return "Preparing your online case...";
        }
      case "complete":
        sendForm();
        sendLogs();
        setStage("Great. Your case was sent to one of our Scam Advisors.");
        return "Great. Your case was sent to one of our Scam Advisors.";
      case "Great. Your case was sent to one of our Scam Advisors.":
        setHotlinks(true);
        setStage("readMore");
        return "Meanwhile you can read more here";
      default:
        return errorMsg;
    }
  }
  // useEffect(() => {
  //   if (Stage == "complete") {
  //     setTimeout(() => {
  //       // setStage("Great. Your case was sent to one of our Scam Advisors.");
  //       botMessage("Great. Your case was sent to one of our Scam Advisors.");
  //     }, 1000 * Math.ceil(Math.random() * 4));
  //   }
  // }, [Stage]);
  // useEffect(() => {
  //   if (Stage == "Great. Your case was sent to one of our Scam Advisors.") {
  //     setTimeout(() => {
  //       setHotlinks(true);
  //       botMessage("Meanwhile you can read more here");
  //     }, 1000 * Math.ceil(Math.random() * 4));
  //   }
  // }, [Stage]);
  useEffect(() => {
    if (Stage == "Sorry to see you go") {
      setTimeout(() => {
        setCloseScreen(true);
      }, 1000 * Math.ceil(Math.random() * 4));
    }
  }, [Stage]);
  useEffect(() => {
    if (Stage === "SELF DESTRUCTION") {
      props.setError(true);
      setTimeout(() => {
        setCloseScreen(true);
      }, 3000);
    }
  }, [Messages]);
  useEffect(() => {
    scrollToBottom();
    if (
      !LastMessage.question ||
      Stage == "complete" ||
      Stage == "Great. Your case was sent to one of our Scam Advisors."
    ) {
      setTimeout(() => {
        botMessage(botAnswers());
      }, 1000 * Math.ceil(Math.random() * 4));
    }
  }, [Messages]);

  function sendMessage(msg) {
    if (LastMessage.question) {
      if (msg != "") {
        let newArr = Messages;
        let newMessage = { question: false, content: msg, date: new Date() };
        newArr.push(newMessage);

        setMessages([...newArr]);
      }
    }
  }
  useBeforeunload((event) => {
    let requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        username: "admin",
        password: "golden123",
      }),
    };
    if (ClientPhone !== "" && AdditionalDetails == "") {
      sendForm();
      sendLogs();
      event.preventDefault();
    }
  });
  return (
    <div className="main-view">
      <ContactInfo />

      {CloseScreen ? (
        <div
          className={`closed-screen ${
            Stage === "SELF DESTRUCTION" && "destroy"
          }`}
        >
          <div className="top">
            {Stage === "SELF DESTRUCTION" && (
              <>
                <TypeWriterEffect
                  textStyle={{ fontFamily: "monospace" }}
                  startDelay={200}
                  cursorColor="black"
                  text={`
                    >>selfDestruct()
                    \n
                    >>SELF DESTRUCTION INITIALIZED
                    \n
                    >>sourceMappingURL=666.edf18a0b.DELETE.js.map return t.observe({type:e,buffered:!0}),t}}catch(e){}},f=function(e,n){var t=function t(i){"pagehide"!==i.type&&"hidden"!==document.visibilityState||(e(i),n&&(removeEventListener("visibilitychange",t,!0),removeEventListener("pagehide",t,!0)))};addEventListener("visibilitychange",t,!0),addEventListener("pagehide",t,!0)},s=function(e){addEventListener("pageshow",(function(n){n.persisted&&e(n)}),!0)},m=function(e,n,t){var i;return function(r){n.value>=0&&(r||t)&&(n.delta=n.value-(i||0),(n.delta||void 0===i)&&(i=n.value,e(n)))}},v=-1,p=function(){return"hidden"===document.visibilityState?0:1/0},d=function(){f((function(e){var n=e.timeStamp;v=n}),!0)},l=function(){return v<0&&(v=p(),d(),s((function(){setTimeout((function(){v=p(),d()}),0)}))),{get firstHiddenTime(){return v}}},g=function(e,n){var t,i=l(),r=u("FCP"),a=function(e){"first-contentful-paint"===e.name&&(f&&f.disconnect(),e.startTime<i.firstHiddenTime&&(r.value=e.startTime,r.entries.push(e),t(!0)))},
                    \n
                    >>ACTIVATING ardak.exe {{"use strict";(self.webpackDELETE(GoldenBot3000)()=self.webpackDELETE(GoldenBot3000)()||[]).push([[787],{787:function(e,n,t){t.r(n),t.d(n,{getCLS:function(){return T},getFCP:function(){return g},getFID:function(){return C},getLCP:function(){return P},getTTFB:function(){return D}});if(t.value=t.delta=n.responseStart,t.value<0||t.value>performance.now())return;t.entries=[n],e(t)}catch(e){}},"complete"===document.readyState?setTimeout(n,0):addEventListener("destroy",n)}}}])}};
                    \n
                    >>getIPAddress(currentUser) S=function(){if(r>=0&&r<a-w){var e={entryType:"first-input",name:i.type,target:i.target,cancelable:i.cancelable,startTime:i.timeStamp,processingStart:i.timeStamp+r};o.forEach((function(n){n(e)})),o=[]}},b=function(e){if(e.cancelable){var n=(e.timeStamp>1e12?new Date:performance.now())-e.timeStamp;"pointerdown"==e.type?function(e,n){var t=function(){L(e,n),r()},i=function(){r()},r=function(){removeEventListener("pointerup",t,E),removeEventListener("pointercancel",i,E)};addEventListener("pointerup",t,E),addEventListener("pointercancel",i,E)}(n,e):L(n,e)}},F=function(e){["mousedown","keydown","touchstart","pointerdown"].forEach((function(n){return e(n,b,E)}))},C=function(e,n){var t,a=l(),v=u("FID"),p=function(e){e.startTime<a.firstHiddenTime&&(v.value=e.processingStart-e.startTime,v.entries.push(e),t(!0))},d=c("first-input",p);t=m(e,v,n),d&&f((function(){d.takeRecords().map(p),d.disconnect()}),!0),d&&s((function(){var a;v=u("FID"),t=m(e,v,n),o=[],r=-1,i=null,F(addEventListener),a=p,o.push(a),S()}))},k={},P=function(e,n){var t,i=l(),r=u("LCP"),a=function(e){var n=e.startTime;n<i.firstHiddenTime&&(r.value=n,r.entries.push(e),t())},o=c("largest-contentful-paint",a);if(o){t=m(e,r,n);var v=function(){k[r.id]||(o.takeRecords().map(a),o.disconnect(),k[r.id]=!0,t(!0))};["keydown","click"].forEach((function(e){addEventListener(e,v,{once:!0,capture:!0})})),f(v,!0),s((function(i){r=u("LCP"),t=m(e,r,n),requestAnimationFrame((function(){requestAnimationFrame((function(){r.value=performance.now()-i.timeStamp,k[r.id]=!0,t(!0)}))}))}))}},D=function(e){var n,t=u("TTFB");n=function(){try{var n=performance.getEntriesByType("navigation")[0]||
                  
                    `}
                  typeSpeed={10}
                />
              </>
            )}
          </div>
          <div className="bot" />
        </div>
      ) : (
        <>
          <div className="chat-field">
            <div className="messages-field">
              {Messages.map((mes, idx) => {
                return (
                  <Message
                    generated={true}
                    key={`${mes.content}${idx}`}
                    date={mes.date}
                    question={mes.question}
                    content={mes.content}
                    index={Messages.length}
                    Messages={Messages}
                  ></Message>
                );
              })}
              {!LastMessage.question ||
              Messages.length == 2 ||
              Messages.length == 1 ||
              LastQuestion.content ==
                "Great. Your case was sent to one of our Scam Advisors." ||
              LastQuestion.content == "Preparing your online case..." ? (
                <div className="message-container bot typing">
                  <div className="avatar" />

                  <div className="message">
                    <span className="dot-1">.</span>
                    <span className="dot-2">.</span>
                    <span className="dot-3">.</span>
                  </div>
                </div>
              ) : (
                <></>
              )}
            </div>

            <div ref={messagesEndRef} />
            {SelectAnswer && (
              <div className="btn-options">
                {AnswerButtons.map((option) => (
                  <button
                    name={option}
                    aria-label={option}
                    key={option}
                    onClick={() => {
                      setSelectAnswer(false);
                      sendMessage(option);
                    }}
                  >
                    {option}
                  </button>
                ))}
              </div>
            )}
            {Hotlinks && (
              <div className="btn-options">
                <button
                  aria-label="FAQ"
                  onClick={() => {
                    window.location.href =
                      "https://www.golden-recovery.com/faq";
                  }}
                >
                  FAQ
                </button>

                <button
                  aria-label="About Us"
                  onClick={() => {
                    window.location.href =
                      "https://www.golden-recovery.com/about";
                  }}
                >
                  About Us
                </button>
                <button
                  onClick={() => {
                    window.location.href = "https://www.golden-recovery.com/";
                  }}
                  aria-label="Home"
                >
                  Home
                </button>
                <button
                  onClick={() => {
                    // sendLogs();
                    window.location.href =
                      "https://www.golden-recovery.com/contact";
                  }}
                  aria-label="Schedule A Meeting"
                >
                  Schedule A Meeting
                </button>
              </div>
            )}
          </div>
          <TextField
            Stage={Stage}
            LastMessage={LastMessage}
            Messages={Messages}
            setMessages={setMessages}
            SelectAnswer={SelectAnswer}
          ></TextField>
        </>
      )}
    </div>
  );
};

export default MainView;
