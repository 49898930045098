import React from "react";
import "./ContactInfo.css";
import { Modal, Button } from "react-bootstrap";
import { useState } from "react";
import pfp from "../../media/img/pfp.png";
const ContactInfo = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <div
        //    onClick={handleShow}
        className="logo"
      >
        GOLDEN RECOVERY
      </div>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Contact Info</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="pfp">
            <img
              height="250"
              width="250"
              alt="Alan profile picture"
              src={pfp}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          {/* <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleClose}>
            Save Changes
          </Button> */}
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ContactInfo;
