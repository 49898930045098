const Countries = [
    {
        "common": "Uruguay",
        "official": "Oriental Republic of Uruguay",
        "nativeName": {
            "spa": {
                "official": "República Oriental del Uruguay",
                "common": "Uruguay"
            }
        }
    },
    {
        "common": "Paraguay",
        "official": "Republic of Paraguay",
        "nativeName": {
            "grn": {
                "official": "Tetã Paraguái",
                "common": "Paraguái"
            },
            "spa": {
                "official": "República de Paraguay",
                "common": "Paraguay"
            }
        }
    },
    {
        "common": "Gambia",
        "official": "Republic of the Gambia",
        "nativeName": {
            "eng": {
                "official": "Republic of the Gambia",
                "common": "Gambia"
            }
        }
    },
    {
        "common": "Djibouti",
        "official": "Republic of Djibouti",
        "nativeName": {
            "ara": {
                "official": "جمهورية جيبوتي",
                "common": "جيبوتي‎"
            },
            "fra": {
                "official": "République de Djibouti",
                "common": "Djibouti"
            }
        }
    },
    {
        "common": "Martinique",
        "official": "Martinique",
        "nativeName": {
            "fra": {
                "official": "Martinique",
                "common": "Martinique"
            }
        }
    },
    {
        "common": "Guam",
        "official": "Guam",
        "nativeName": {
            "cha": {
                "official": "Guåhån",
                "common": "Guåhån"
            },
            "eng": {
                "official": "Guam",
                "common": "Guam"
            },
            "spa": {
                "official": "Guam",
                "common": "Guam"
            }
        }
    },
    {
        "common": "Georgia",
        "official": "Georgia",
        "nativeName": {
            "kat": {
                "official": "საქართველო",
                "common": "საქართველო"
            }
        }
    },
    {
        "common": "United States",
        "official": "United States of America",
        "nativeName": {
            "eng": {
                "official": "United States of America",
                "common": "United States"
            }
        }
    },
    {
        "common": "Belize",
        "official": "Belize",
        "nativeName": {
            "bjz": {
                "official": "Belize",
                "common": "Belize"
            },
            "eng": {
                "official": "Belize",
                "common": "Belize"
            },
            "spa": {
                "official": "Belice",
                "common": "Belice"
            }
        }
    },
    {
        "common": "Mauritius",
        "official": "Republic of Mauritius",
        "nativeName": {
            "eng": {
                "official": "Republic of Mauritius",
                "common": "Mauritius"
            },
            "fra": {
                "official": "République de Maurice",
                "common": "Maurice"
            },
            "mfe": {
                "official": "Republik Moris",
                "common": "Moris"
            }
        }
    },
    {
        "common": "Lebanon",
        "official": "Lebanese Republic",
        "nativeName": {
            "ara": {
                "official": "الجمهورية اللبنانية",
                "common": "لبنان"
            },
            "fra": {
                "official": "République libanaise",
                "common": "Liban"
            }
        }
    },
    {
        "common": "Saudi Arabia",
        "official": "Kingdom of Saudi Arabia",
        "nativeName": {
            "ara": {
                "official": "المملكة العربية السعودية",
                "common": "العربية السعودية"
            }
        }
    },
    {
        "common": "Ecuador",
        "official": "Republic of Ecuador",
        "nativeName": {
            "spa": {
                "official": "República del Ecuador",
                "common": "Ecuador"
            }
        }
    },
    {
        "common": "Marshall Islands",
        "official": "Republic of the Marshall Islands",
        "nativeName": {
            "eng": {
                "official": "Republic of the Marshall Islands",
                "common": "Marshall Islands"
            },
            "mah": {
                "official": "Republic of the Marshall Islands",
                "common": "M̧ajeļ"
            }
        }
    },
    {
        "common": "Brazil",
        "official": "Federative Republic of Brazil",
        "nativeName": {
            "por": {
                "official": "República Federativa do Brasil",
                "common": "Brasil"
            }
        }
    },
    {
        "common": "Uganda",
        "official": "Republic of Uganda",
        "nativeName": {
            "eng": {
                "official": "Republic of Uganda",
                "common": "Uganda"
            },
            "swa": {
                "official": "Republic of Uganda",
                "common": "Uganda"
            }
        }
    },
    {
        "common": "Qatar",
        "official": "State of Qatar",
        "nativeName": {
            "ara": {
                "official": "دولة قطر",
                "common": "قطر"
            }
        }
    },
    {
        "common": "Timor-Leste",
        "official": "Democratic Republic of Timor-Leste",
        "nativeName": {
            "por": {
                "official": "República Democrática de Timor-Leste",
                "common": "Timor-Leste"
            },
            "tet": {
                "official": "Repúblika Demokrátika Timór-Leste",
                "common": "Timór-Leste"
            }
        }
    },
    {
        "common": "Mongolia",
        "official": "Mongolia",
        "nativeName": {
            "mon": {
                "official": "Монгол улс",
                "common": "Монгол улс"
            }
        }
    },
    {
        "common": "Chile",
        "official": "Republic of Chile",
        "nativeName": {
            "spa": {
                "official": "República de Chile",
                "common": "Chile"
            }
        }
    },
    {
        "common": "Liberia",
        "official": "Republic of Liberia",
        "nativeName": {
            "eng": {
                "official": "Republic of Liberia",
                "common": "Liberia"
            }
        }
    },
    {
        "common": "Nauru",
        "official": "Republic of Nauru",
        "nativeName": {
            "eng": {
                "official": "Republic of Nauru",
                "common": "Nauru"
            },
            "nau": {
                "official": "Republic of Nauru",
                "common": "Nauru"
            }
        }
    },
    {
        "common": "Réunion",
        "official": "Réunion Island",
        "nativeName": {
            "fra": {
                "official": "Ile de la Réunion",
                "common": "La Réunion"
            }
        }
    },
    {
        "common": "Montserrat",
        "official": "Montserrat",
        "nativeName": {
            "eng": {
                "official": "Montserrat",
                "common": "Montserrat"
            }
        }
    },
    {
        "common": "Taiwan",
        "official": "Republic of China (Taiwan)",
        "nativeName": {
            "zho": {
                "official": "中華民國",
                "common": "台灣"
            }
        }
    },
    {
        "common": "Antarctica",
        "official": "Antarctica"
    },
    {
        "common": "Saint Pierre and Miquelon",
        "official": "Saint Pierre and Miquelon",
        "nativeName": {
            "fra": {
                "official": "Collectivité territoriale de Saint-Pierre-et-Miquelon",
                "common": "Saint-Pierre-et-Miquelon"
            }
        }
    },
    {
        "common": "Argentina",
        "official": "Argentine Republic",
        "nativeName": {
            "grn": {
                "official": "Argentine Republic",
                "common": "Argentina"
            },
            "spa": {
                "official": "República Argentina",
                "common": "Argentina"
            }
        }
    },
    {
        "common": "Togo",
        "official": "Togolese Republic",
        "nativeName": {
            "fra": {
                "official": "République togolaise",
                "common": "Togo"
            }
        }
    },
    {
        "common": "Austria",
        "official": "Republic of Austria",
        "nativeName": {
            "bar": {
                "official": "Republik Österreich",
                "common": "Österreich"
            }
        }
    },
    {
        "common": "Grenada",
        "official": "Grenada",
        "nativeName": {
            "eng": {
                "official": "Grenada",
                "common": "Grenada"
            }
        }
    },
    {
        "common": "Faroe Islands",
        "official": "Faroe Islands",
        "nativeName": {
            "dan": {
                "official": "Færøerne",
                "common": "Færøerne"
            },
            "fao": {
                "official": "Føroyar",
                "common": "Føroyar"
            }
        }
    },
    {
        "common": "Morocco",
        "official": "Kingdom of Morocco",
        "nativeName": {
            "ara": {
                "official": "المملكة المغربية",
                "common": "المغرب"
            },
            "ber": {
                "official": "ⵜⴰⴳⵍⴷⵉⵜ ⵏ ⵍⵎⵖⵔⵉⴱ",
                "common": "ⵍⵎⴰⵖⵔⵉⴱ"
            }
        }
    },
    {
        "common": "Anguilla",
        "official": "Anguilla",
        "nativeName": {
            "eng": {
                "official": "Anguilla",
                "common": "Anguilla"
            }
        }
    },
    {
        "common": "Palau",
        "official": "Republic of Palau",
        "nativeName": {
            "eng": {
                "official": "Republic of Palau",
                "common": "Palau"
            },
            "pau": {
                "official": "Beluu er a Belau",
                "common": "Belau"
            }
        }
    },
    {
        "common": "Northern Mariana Islands",
        "official": "Commonwealth of the Northern Mariana Islands",
        "nativeName": {
            "cal": {
                "official": "Commonwealth of the Northern Mariana Islands",
                "common": "Northern Mariana Islands"
            },
            "cha": {
                "official": "Sankattan Siha Na Islas Mariånas",
                "common": "Na Islas Mariånas"
            },
            "eng": {
                "official": "Commonwealth of the Northern Mariana Islands",
                "common": "Northern Mariana Islands"
            }
        }
    },
    {
        "common": "Mauritania",
        "official": "Islamic Republic of Mauritania",
        "nativeName": {
            "ara": {
                "official": "الجمهورية الإسلامية الموريتانية",
                "common": "موريتانيا"
            }
        }
    },
    {
        "common": "Ukraine",
        "official": "Ukraine",
        "nativeName": {
            "ukr": {
                "official": "Україна",
                "common": "Україна"
            }
        }
    },
    {
        "common": "China",
        "official": "People's Republic of China",
        "nativeName": {
            "zho": {
                "official": "中华人民共和国",
                "common": "中国"
            }
        }
    },
    {
        "common": "Lesotho",
        "official": "Kingdom of Lesotho",
        "nativeName": {
            "eng": {
                "official": "Kingdom of Lesotho",
                "common": "Lesotho"
            },
            "sot": {
                "official": "Kingdom of Lesotho",
                "common": "Lesotho"
            }
        }
    },
    {
        "common": "Cyprus",
        "official": "Republic of Cyprus",
        "nativeName": {
            "ell": {
                "official": "Δημοκρατία της Κύπρος",
                "common": "Κύπρος"
            },
            "tur": {
                "official": "Kıbrıs Cumhuriyeti",
                "common": "Kıbrıs"
            }
        }
    },
    {
        "common": "DR Congo",
        "official": "Democratic Republic of the Congo",
        "nativeName": {
            "fra": {
                "official": "République démocratique du Congo",
                "common": "RD Congo"
            },
            "kon": {
                "official": "Repubilika ya Kongo Demokratiki",
                "common": "Repubilika ya Kongo Demokratiki"
            },
            "lin": {
                "official": "Republiki ya Kongó Demokratiki",
                "common": "Republiki ya Kongó Demokratiki"
            },
            "lua": {
                "official": "Ditunga dia Kongu wa Mungalaata",
                "common": "Ditunga dia Kongu wa Mungalaata"
            },
            "swa": {
                "official": "Jamhuri ya Kidemokrasia ya Kongo",
                "common": "Jamhuri ya Kidemokrasia ya Kongo"
            }
        }
    },
    {
        "common": "Russia",
        "official": "Russian Federation",
        "nativeName": {
            "rus": {
                "official": "Российская Федерация",
                "common": "Россия"
            }
        }
    },
    {
        "common": "Bangladesh",
        "official": "People's Republic of Bangladesh",
        "nativeName": {
            "ben": {
                "official": "বাংলাদেশ গণপ্রজাতন্ত্রী",
                "common": "বাংলাদেশ"
            }
        }
    },
    {
        "common": "South Africa",
        "official": "Republic of South Africa",
        "nativeName": {
            "afr": {
                "official": "Republiek van Suid-Afrika",
                "common": "South Africa"
            },
            "eng": {
                "official": "Republic of South Africa",
                "common": "South Africa"
            },
            "nbl": {
                "official": "IRiphabliki yeSewula Afrika",
                "common": "Sewula Afrika"
            },
            "nso": {
                "official": "Rephaboliki ya Afrika-Borwa ",
                "common": "Afrika-Borwa"
            },
            "sot": {
                "official": "Rephaboliki ya Afrika Borwa",
                "common": "Afrika Borwa"
            },
            "ssw": {
                "official": "IRiphabhulikhi yeNingizimu Afrika",
                "common": "Ningizimu Afrika"
            },
            "tsn": {
                "official": "Rephaboliki ya Aforika Borwa",
                "common": "Aforika Borwa"
            },
            "tso": {
                "official": "Riphabliki ra Afrika Dzonga",
                "common": "Afrika Dzonga"
            },
            "ven": {
                "official": "Riphabuḽiki ya Afurika Tshipembe",
                "common": "Afurika Tshipembe"
            },
            "xho": {
                "official": "IRiphabliki yaseMzantsi Afrika",
                "common": "Mzantsi Afrika"
            },
            "zul": {
                "official": "IRiphabliki yaseNingizimu Afrika",
                "common": "Ningizimu Afrika"
            }
        }
    },
    {
        "common": "Curaçao",
        "official": "Country of Curaçao",
        "nativeName": {
            "eng": {
                "official": "Country of Curaçao",
                "common": "Curaçao"
            },
            "nld": {
                "official": "Land Curaçao",
                "common": "Curaçao"
            },
            "pap": {
                "official": "Pais Kòrsou",
                "common": "Pais Kòrsou"
            }
        }
    },
    {
        "common": "Guatemala",
        "official": "Republic of Guatemala",
        "nativeName": {
            "spa": {
                "official": "República de Guatemala",
                "common": "Guatemala"
            }
        }
    },
    {
        "common": "Puerto Rico",
        "official": "Commonwealth of Puerto Rico",
        "nativeName": {
            "eng": {
                "official": "Commonwealth of Puerto Rico",
                "common": "Puerto Rico"
            },
            "spa": {
                "official": "Estado Libre Asociado de Puerto Rico",
                "common": "Puerto Rico"
            }
        }
    },
    {
        "common": "Antigua and Barbuda",
        "official": "Antigua and Barbuda",
        "nativeName": {
            "eng": {
                "official": "Antigua and Barbuda",
                "common": "Antigua and Barbuda"
            }
        }
    },
    {
        "common": "Israel",
        "official": "State of Israel",
        "nativeName": {
            "ara": {
                "official": "دولة إسرائيل",
                "common": "إسرائيل"
            },
            "heb": {
                "official": "מדינת ישראל",
                "common": "ישראל"
            }
        }
    },
    {
        "common": "Guyana",
        "official": "Co-operative Republic of Guyana",
        "nativeName": {
            "eng": {
                "official": "Co-operative Republic of Guyana",
                "common": "Guyana"
            }
        }
    },
    {
        "common": "Cayman Islands",
        "official": "Cayman Islands",
        "nativeName": {
            "eng": {
                "official": "Cayman Islands",
                "common": "Cayman Islands"
            }
        }
    },
    {
        "common": "Croatia",
        "official": "Republic of Croatia",
        "nativeName": {
            "hrv": {
                "official": "Republika Hrvatska",
                "common": "Hrvatska"
            }
        }
    },
    {
        "common": "Iceland",
        "official": "Iceland",
        "nativeName": {
            "isl": {
                "official": "Ísland",
                "common": "Ísland"
            }
        }
    },
    {
        "common": "Caribbean Netherlands",
        "official": "Bonaire, Sint Eustatius and Saba",
        "nativeName": {
            "nld": {
                "official": "Bonaire, Sint Eustatius en Saba",
                "common": "Caribisch Nederland"
            },
            "pap": {
                "official": "Boneiru, Sint Eustatius y Saba",
                "common": "Boneiru, Sint Eustatius y Saba"
            }
        }
    },
    {
        "common": "Sint Maarten",
        "official": "Sint Maarten",
        "nativeName": {
            "eng": {
                "official": "Sint Maarten",
                "common": "Sint Maarten"
            },
            "fra": {
                "official": "Saint-Martin",
                "common": "Saint-Martin"
            },
            "nld": {
                "official": "Sint Maarten",
                "common": "Sint Maarten"
            }
        }
    },
    {
        "common": "Namibia",
        "official": "Republic of Namibia",
        "nativeName": {
            "afr": {
                "official": "Republiek van Namibië",
                "common": "Namibië"
            },
            "deu": {
                "official": "Republik Namibia",
                "common": "Namibia"
            },
            "eng": {
                "official": "Republic of Namibia",
                "common": "Namibia"
            },
            "her": {
                "official": "Republic of Namibia",
                "common": "Namibia"
            },
            "hgm": {
                "official": "Republic of Namibia",
                "common": "Namibia"
            },
            "kwn": {
                "official": "Republic of Namibia",
                "common": "Namibia"
            },
            "loz": {
                "official": "Republic of Namibia",
                "common": "Namibia"
            },
            "ndo": {
                "official": "Republic of Namibia",
                "common": "Namibia"
            },
            "tsn": {
                "official": "Lefatshe la Namibia",
                "common": "Namibia"
            }
        }
    },
    {
        "common": "Dominica",
        "official": "Commonwealth of Dominica",
        "nativeName": {
            "eng": {
                "official": "Commonwealth of Dominica",
                "common": "Dominica"
            }
        }
    },
    {
        "common": "Gibraltar",
        "official": "Gibraltar",
        "nativeName": {
            "eng": {
                "official": "Gibraltar",
                "common": "Gibraltar"
            }
        }
    },
    {
        "common": "Senegal",
        "official": "Republic of Senegal",
        "nativeName": {
            "fra": {
                "official": "République du Sénégal",
                "common": "Sénégal"
            }
        }
    },
    {
        "common": "Saint Kitts and Nevis",
        "official": "Federation of Saint Christopher and Nevis",
        "nativeName": {
            "eng": {
                "official": "Federation of Saint Christopher and Nevis",
                "common": "Saint Kitts and Nevis"
            }
        }
    },
    {
        "common": "Oman",
        "official": "Sultanate of Oman",
        "nativeName": {
            "ara": {
                "official": "سلطنة عمان",
                "common": "عمان"
            }
        }
    },
    {
        "common": "Kuwait",
        "official": "State of Kuwait",
        "nativeName": {
            "ara": {
                "official": "دولة الكويت",
                "common": "الكويت"
            }
        }
    },
    {
        "common": "Åland Islands",
        "official": "Åland Islands",
        "nativeName": {
            "swe": {
                "official": "Landskapet Åland",
                "common": "Åland"
            }
        }
    },
    {
        "common": "United States Virgin Islands",
        "official": "Virgin Islands of the United States",
        "nativeName": {
            "eng": {
                "official": "Virgin Islands of the United States",
                "common": "United States Virgin Islands"
            }
        }
    },
    {
        "common": "Bouvet Island",
        "official": "Bouvet Island",
        "nativeName": {
            "nor": {
                "official": "Bouvetøya",
                "common": "Bouvetøya"
            }
        }
    },
    {
        "common": "United Kingdom",
        "official": "United Kingdom of Great Britain and Northern Ireland",
        "nativeName": {
            "eng": {
                "official": "United Kingdom of Great Britain and Northern Ireland",
                "common": "United Kingdom"
            }
        }
    },
    {
        "common": "Honduras",
        "official": "Republic of Honduras",
        "nativeName": {
            "spa": {
                "official": "República de Honduras",
                "common": "Honduras"
            }
        }
    },
    {
        "common": "South Georgia",
        "official": "South Georgia and the South Sandwich Islands",
        "nativeName": {
            "eng": {
                "official": "South Georgia and the South Sandwich Islands",
                "common": "South Georgia"
            }
        }
    },
    {
        "common": "Cambodia",
        "official": "Kingdom of Cambodia",
        "nativeName": {
            "khm": {
                "official": "ព្រះរាជាណាចក្រកម្ពុជា",
                "common": "Kâmpŭchéa"
            }
        }
    },
    {
        "common": "North Macedonia",
        "official": "Republic of North Macedonia",
        "nativeName": {
            "mkd": {
                "official": "Република Северна Македонија",
                "common": "Македонија"
            }
        }
    },
    {
        "common": "Iran",
        "official": "Islamic Republic of Iran",
        "nativeName": {
            "fas": {
                "official": "جمهوری اسلامی ایران",
                "common": "ایران"
            }
        }
    },
    {
        "common": "Panama",
        "official": "Republic of Panama",
        "nativeName": {
            "spa": {
                "official": "República de Panamá",
                "common": "Panamá"
            }
        }
    },
    {
        "common": "Cook Islands",
        "official": "Cook Islands",
        "nativeName": {
            "eng": {
                "official": "Cook Islands",
                "common": "Cook Islands"
            },
            "rar": {
                "official": "Kūki 'Āirani",
                "common": "Kūki 'Āirani"
            }
        }
    },
    {
        "common": "Andorra",
        "official": "Principality of Andorra",
        "nativeName": {
            "cat": {
                "official": "Principat d'Andorra",
                "common": "Andorra"
            }
        }
    },
    {
        "common": "Burundi",
        "official": "Republic of Burundi",
        "nativeName": {
            "fra": {
                "official": "République du Burundi",
                "common": "Burundi"
            },
            "run": {
                "official": "Republika y'Uburundi ",
                "common": "Uburundi"
            }
        }
    },
    {
        "common": "Trinidad and Tobago",
        "official": "Republic of Trinidad and Tobago",
        "nativeName": {
            "eng": {
                "official": "Republic of Trinidad and Tobago",
                "common": "Trinidad and Tobago"
            }
        }
    },
    {
        "common": "Comoros",
        "official": "Union of the Comoros",
        "nativeName": {
            "ara": {
                "official": "الاتحاد القمري",
                "common": "القمر‎"
            },
            "fra": {
                "official": "Union des Comores",
                "common": "Comores"
            },
            "zdj": {
                "official": "Udzima wa Komori",
                "common": "Komori"
            }
        }
    },
    {
        "common": "French Southern and Antarctic Lands",
        "official": "Territory of the French Southern and Antarctic Lands",
        "nativeName": {
            "fra": {
                "official": "Territoire des Terres australes et antarctiques françaises",
                "common": "Terres australes et antarctiques françaises"
            }
        }
    },
    {
        "common": "Kosovo",
        "official": "Republic of Kosovo",
        "nativeName": {
            "sqi": {
                "official": "Republika e Kosovës",
                "common": "Kosova"
            },
            "srp": {
                "official": "Република Косово",
                "common": "Косово"
            }
        }
    },
    {
        "common": "Nigeria",
        "official": "Federal Republic of Nigeria",
        "nativeName": {
            "eng": {
                "official": "Federal Republic of Nigeria",
                "common": "Nigeria"
            }
        }
    },
    {
        "common": "Poland",
        "official": "Republic of Poland",
        "nativeName": {
            "pol": {
                "official": "Rzeczpospolita Polska",
                "common": "Polska"
            }
        }
    },
    {
        "common": "Niue",
        "official": "Niue",
        "nativeName": {
            "eng": {
                "official": "Niue",
                "common": "Niue"
            },
            "niu": {
                "official": "Niuē",
                "common": "Niuē"
            }
        }
    },
    {
        "common": "New Caledonia",
        "official": "New Caledonia",
        "nativeName": {
            "fra": {
                "official": "Nouvelle-Calédonie",
                "common": "Nouvelle-Calédonie"
            }
        }
    },
    {
        "common": "Ethiopia",
        "official": "Federal Democratic Republic of Ethiopia",
        "nativeName": {
            "amh": {
                "official": "የኢትዮጵያ ፌዴራላዊ ዲሞክራሲያዊ ሪፐብሊክ",
                "common": "ኢትዮጵያ"
            }
        }
    },
    {
        "common": "Germany",
        "official": "Federal Republic of Germany",
        "nativeName": {
            "deu": {
                "official": "Bundesrepublik Deutschland",
                "common": "Deutschland"
            }
        }
    },
    {
        "common": "Azerbaijan",
        "official": "Republic of Azerbaijan",
        "nativeName": {
            "aze": {
                "official": "Azərbaycan Respublikası",
                "common": "Azərbaycan"
            },
            "rus": {
                "official": "Азербайджанская Республика",
                "common": "Азербайджан"
            }
        }
    },
    {
        "common": "Netherlands",
        "official": "Kingdom of the Netherlands",
        "nativeName": {
            "nld": {
                "official": "Koninkrijk der Nederlanden",
                "common": "Nederland"
            }
        }
    },
    {
        "common": "France",
        "official": "French Republic",
        "nativeName": {
            "fra": {
                "official": "République française",
                "common": "France"
            }
        }
    },
    {
        "common": "Nepal",
        "official": "Federal Democratic Republic of Nepal",
        "nativeName": {
            "nep": {
                "official": "नेपाल संघीय लोकतान्त्रिक गणतन्त्र",
                "common": "नेपाल"
            }
        }
    },
    {
        "common": "Barbados",
        "official": "Barbados",
        "nativeName": {
            "eng": {
                "official": "Barbados",
                "common": "Barbados"
            }
        }
    },
    {
        "common": "Jersey",
        "official": "Bailiwick of Jersey",
        "nativeName": {
            "eng": {
                "official": "Bailiwick of Jersey",
                "common": "Jersey"
            },
            "fra": {
                "official": "Bailliage de Jersey",
                "common": "Jersey"
            },
            "nrf": {
                "official": "Bailliage dé Jèrri",
                "common": "Jèrri"
            }
        }
    },
    {
        "common": "India",
        "official": "Republic of India",
        "nativeName": {
            "eng": {
                "official": "Republic of India",
                "common": "India"
            },
            "hin": {
                "official": "भारत गणराज्य",
                "common": "भारत"
            },
            "tam": {
                "official": "இந்தியக் குடியரசு",
                "common": "இந்தியா"
            }
        }
    },
    {
        "common": "Kyrgyzstan",
        "official": "Kyrgyz Republic",
        "nativeName": {
            "kir": {
                "official": "Кыргыз Республикасы",
                "common": "Кыргызстан"
            },
            "rus": {
                "official": "Кыргызская Республика",
                "common": "Киргизия"
            }
        }
    },
    {
        "common": "South Sudan",
        "official": "Republic of South Sudan",
        "nativeName": {
            "eng": {
                "official": "Republic of South Sudan",
                "common": "South Sudan"
            }
        }
    },
    {
        "common": "Iraq",
        "official": "Republic of Iraq",
        "nativeName": {
            "ara": {
                "official": "جمهورية العراق",
                "common": "العراق"
            },
            "arc": {
                "official": "ܩܘܼܛܢܵܐ ܐܝܼܪܲܩ",
                "common": "ܩܘܼܛܢܵܐ"
            },
            "ckb": {
                "official": "کۆماری عێراق",
                "common": "کۆماری"
            }
        }
    },
    {
        "common": "Italy",
        "official": "Italian Republic",
        "nativeName": {
            "ita": {
                "official": "Repubblica italiana",
                "common": "Italia"
            }
        }
    },
    {
        "common": "Cuba",
        "official": "Republic of Cuba",
        "nativeName": {
            "spa": {
                "official": "República de Cuba",
                "common": "Cuba"
            }
        }
    },
    {
        "common": "Bhutan",
        "official": "Kingdom of Bhutan",
        "nativeName": {
            "dzo": {
                "official": "འབྲུག་རྒྱལ་ཁབ་",
                "common": "འབྲུག་ཡུལ་"
            }
        }
    },
    {
        "common": "Bahamas",
        "official": "Commonwealth of the Bahamas",
        "nativeName": {
            "eng": {
                "official": "Commonwealth of the Bahamas",
                "common": "Bahamas"
            }
        }
    },
    {
        "common": "Norway",
        "official": "Kingdom of Norway",
        "nativeName": {
            "nno": {
                "official": "Kongeriket Noreg",
                "common": "Noreg"
            },
            "nob": {
                "official": "Kongeriket Norge",
                "common": "Norge"
            },
            "smi": {
                "official": "Norgga gonagasriika",
                "common": "Norgga"
            }
        }
    },
    {
        "common": "Lithuania",
        "official": "Republic of Lithuania",
        "nativeName": {
            "lit": {
                "official": "Lietuvos Respublikos",
                "common": "Lietuva"
            }
        }
    },
    {
        "common": "Kenya",
        "official": "Republic of Kenya",
        "nativeName": {
            "eng": {
                "official": "Republic of Kenya",
                "common": "Kenya"
            },
            "swa": {
                "official": "Republic of Kenya",
                "common": "Kenya"
            }
        }
    },
    {
        "common": "Pitcairn Islands",
        "official": "Pitcairn Group of Islands",
        "nativeName": {
            "eng": {
                "official": "Pitcairn Group of Islands",
                "common": "Pitcairn Islands"
            }
        }
    },
    {
        "common": "Sweden",
        "official": "Kingdom of Sweden",
        "nativeName": {
            "swe": {
                "official": "Konungariket Sverige",
                "common": "Sverige"
            }
        }
    },
    {
        "common": "Guadeloupe",
        "official": "Guadeloupe",
        "nativeName": {
            "fra": {
                "official": "Guadeloupe",
                "common": "Guadeloupe"
            }
        }
    },
    {
        "common": "Gabon",
        "official": "Gabonese Republic",
        "nativeName": {
            "fra": {
                "official": "République gabonaise",
                "common": "Gabon"
            }
        }
    },
    {
        "common": "Macau",
        "official": "Macao Special Administrative Region of the People's Republic of China",
        "nativeName": {
            "por": {
                "official": "Região Administrativa Especial de Macau da República Popular da China",
                "common": "Macau"
            },
            "zho": {
                "official": "中华人民共和国澳门特别行政区",
                "common": "澳门"
            }
        }
    },
    {
        "common": "Guernsey",
        "official": "Bailiwick of Guernsey",
        "nativeName": {
            "eng": {
                "official": "Bailiwick of Guernsey",
                "common": "Guernsey"
            },
            "fra": {
                "official": "Bailliage de Guernesey",
                "common": "Guernesey"
            },
            "nfr": {
                "official": "Dgèrnésiais",
                "common": "Dgèrnésiais"
            }
        }
    },
    {
        "common": "Rwanda",
        "official": "Republic of Rwanda",
        "nativeName": {
            "eng": {
                "official": "Republic of Rwanda",
                "common": "Rwanda"
            },
            "fra": {
                "official": "République rwandaise",
                "common": "Rwanda"
            },
            "kin": {
                "official": "Repubulika y'u Rwanda",
                "common": "Rwanda"
            }
        }
    },
    {
        "common": "Syria",
        "official": "Syrian Arab Republic",
        "nativeName": {
            "ara": {
                "official": "الجمهورية العربية السورية",
                "common": "سوريا"
            }
        }
    },
    {
        "common": "Canada",
        "official": "Canada",
        "nativeName": {
            "eng": {
                "official": "Canada",
                "common": "Canada"
            },
            "fra": {
                "official": "Canada",
                "common": "Canada"
            }
        }
    },
    {
        "common": "Algeria",
        "official": "People's Democratic Republic of Algeria",
        "nativeName": {
            "ara": {
                "official": "الجمهورية الديمقراطية الشعبية الجزائرية",
                "common": "الجزائر"
            }
        }
    },
    {
        "common": "British Indian Ocean Territory",
        "official": "British Indian Ocean Territory",
        "nativeName": {
            "eng": {
                "official": "British Indian Ocean Territory",
                "common": "British Indian Ocean Territory"
            }
        }
    },
    {
        "common": "Western Sahara",
        "official": "Sahrawi Arab Democratic Republic",
        "nativeName": {
            "ber": {
                "official": "Sahrawi Arab Democratic Republic",
                "common": "Western Sahara"
            },
            "mey": {
                "official": "الجمهورية العربية الصحراوية الديمقراطية",
                "common": "الصحراء الغربية"
            },
            "spa": {
                "official": "República Árabe Saharaui Democrática",
                "common": "Sahara Occidental"
            }
        }
    },
    {
        "common": "Isle of Man",
        "official": "Isle of Man",
        "nativeName": {
            "eng": {
                "official": "Isle of Man",
                "common": "Isle of Man"
            },
            "glv": {
                "official": "Ellan Vannin or Mannin",
                "common": "Mannin"
            }
        }
    },
    {
        "common": "Botswana",
        "official": "Republic of Botswana",
        "nativeName": {
            "eng": {
                "official": "Republic of Botswana",
                "common": "Botswana"
            },
            "tsn": {
                "official": "Lefatshe la Botswana",
                "common": "Botswana"
            }
        }
    },
    {
        "common": "Kazakhstan",
        "official": "Republic of Kazakhstan",
        "nativeName": {
            "kaz": {
                "official": "Қазақстан Республикасы",
                "common": "Қазақстан"
            },
            "rus": {
                "official": "Республика Казахстан",
                "common": "Казахстан"
            }
        }
    },
    {
        "common": "Venezuela",
        "official": "Bolivarian Republic of Venezuela",
        "nativeName": {
            "spa": {
                "official": "República Bolivariana de Venezuela",
                "common": "Venezuela"
            }
        }
    },
    {
        "common": "French Polynesia",
        "official": "French Polynesia",
        "nativeName": {
            "fra": {
                "official": "Polynésie française",
                "common": "Polynésie française"
            }
        }
    },
    {
        "common": "Sudan",
        "official": "Republic of the Sudan",
        "nativeName": {
            "ara": {
                "official": "جمهورية السودان",
                "common": "السودان"
            },
            "eng": {
                "official": "Republic of the Sudan",
                "common": "Sudan"
            }
        }
    },
    {
        "common": "Wallis and Futuna",
        "official": "Territory of the Wallis and Futuna Islands",
        "nativeName": {
            "fra": {
                "official": "Territoire des îles Wallis et Futuna",
                "common": "Wallis et Futuna"
            }
        }
    },
    {
        "common": "Slovakia",
        "official": "Slovak Republic",
        "nativeName": {
            "slk": {
                "official": "Slovenská republika",
                "common": "Slovensko"
            }
        }
    },
    {
        "common": "Saint Barthélemy",
        "official": "Collectivity of Saint Barthélemy",
        "nativeName": {
            "fra": {
                "official": "Collectivité de Saint-Barthélemy",
                "common": "Saint-Barthélemy"
            }
        }
    },
    {
        "common": "Christmas Island",
        "official": "Territory of Christmas Island",
        "nativeName": {
            "eng": {
                "official": "Territory of Christmas Island",
                "common": "Christmas Island"
            }
        }
    },
    {
        "common": "Solomon Islands",
        "official": "Solomon Islands",
        "nativeName": {
            "eng": {
                "official": "Solomon Islands",
                "common": "Solomon Islands"
            }
        }
    },
    {
        "common": "Latvia",
        "official": "Republic of Latvia",
        "nativeName": {
            "lav": {
                "official": "Latvijas Republikas",
                "common": "Latvija"
            }
        }
    },
    {
        "common": "British Virgin Islands",
        "official": "Virgin Islands",
        "nativeName": {
            "eng": {
                "official": "Virgin Islands",
                "common": "British Virgin Islands"
            }
        }
    },
    {
        "common": "Jamaica",
        "official": "Jamaica",
        "nativeName": {
            "eng": {
                "official": "Jamaica",
                "common": "Jamaica"
            },
            "jam": {
                "official": "Jamaica",
                "common": "Jamaica"
            }
        }
    },
    {
        "common": "Afghanistan",
        "official": "Islamic Republic of Afghanistan",
        "nativeName": {
            "prs": {
                "official": "جمهوری اسلامی افغانستان",
                "common": "افغانستان"
            },
            "pus": {
                "official": "د افغانستان اسلامي جمهوریت",
                "common": "افغانستان"
            },
            "tuk": {
                "official": "Owganystan Yslam Respublikasy",
                "common": "Owganystan"
            }
        }
    },
    {
        "common": "Serbia",
        "official": "Republic of Serbia",
        "nativeName": {
            "srp": {
                "official": "Република Србија",
                "common": "Србија"
            }
        }
    },
    {
        "common": "Tajikistan",
        "official": "Republic of Tajikistan",
        "nativeName": {
            "rus": {
                "official": "Республика Таджикистан",
                "common": "Таджикистан"
            },
            "tgk": {
                "official": "Ҷумҳурии Тоҷикистон",
                "common": "Тоҷикистон"
            }
        }
    },
    {
        "common": "Tonga",
        "official": "Kingdom of Tonga",
        "nativeName": {
            "eng": {
                "official": "Kingdom of Tonga",
                "common": "Tonga"
            },
            "ton": {
                "official": "Kingdom of Tonga",
                "common": "Tonga"
            }
        }
    },
    {
        "common": "Kiribati",
        "official": "Independent and Sovereign Republic of Kiribati",
        "nativeName": {
            "eng": {
                "official": "Independent and Sovereign Republic of Kiribati",
                "common": "Kiribati"
            },
            "gil": {
                "official": "Ribaberiki Kiribati",
                "common": "Kiribati"
            }
        }
    },
    {
        "common": "Eritrea",
        "official": "State of Eritrea",
        "nativeName": {
            "ara": {
                "official": "دولة إرتريا",
                "common": "إرتريا‎"
            },
            "eng": {
                "official": "State of Eritrea",
                "common": "Eritrea"
            },
            "tir": {
                "official": "ሃገረ ኤርትራ",
                "common": "ኤርትራ"
            }
        }
    },
    {
        "common": "Mali",
        "official": "Republic of Mali",
        "nativeName": {
            "fra": {
                "official": "République du Mali",
                "common": "Mali"
            }
        }
    },
    {
        "common": "Haiti",
        "official": "Republic of Haiti",
        "nativeName": {
            "fra": {
                "official": "République d'Haïti",
                "common": "Haïti"
            },
            "hat": {
                "official": "Repiblik Ayiti",
                "common": "Ayiti"
            }
        }
    },
    {
        "common": "Vanuatu",
        "official": "Republic of Vanuatu",
        "nativeName": {
            "bis": {
                "official": "Ripablik blong Vanuatu",
                "common": "Vanuatu"
            },
            "eng": {
                "official": "Republic of Vanuatu",
                "common": "Vanuatu"
            },
            "fra": {
                "official": "République de Vanuatu",
                "common": "Vanuatu"
            }
        }
    },
    {
        "common": "Bosnia and Herzegovina",
        "official": "Bosnia and Herzegovina",
        "nativeName": {
            "bos": {
                "official": "Bosna i Hercegovina",
                "common": "Bosna i Hercegovina"
            },
            "hrv": {
                "official": "Bosna i Hercegovina",
                "common": "Bosna i Hercegovina"
            },
            "srp": {
                "official": "Боснa и Херцеговина",
                "common": "Боснa и Херцеговина"
            }
        }
    },
    {
        "common": "Vatican City",
        "official": "Vatican City State",
        "nativeName": {
            "ita": {
                "official": "Stato della Città del Vaticano",
                "common": "Vaticano"
            },
            "lat": {
                "official": "Status Civitatis Vaticanæ",
                "common": "Vaticanæ"
            }
        }
    },
    {
        "common": "Benin",
        "official": "Republic of Benin",
        "nativeName": {
            "fra": {
                "official": "République du Bénin",
                "common": "Bénin"
            }
        }
    },
    {
        "common": "Svalbard and Jan Mayen",
        "official": "Svalbard og Jan Mayen",
        "nativeName": {
            "nor": {
                "official": "Svalbard og Jan Mayen",
                "common": "Svalbard og Jan Mayen"
            }
        }
    },
    {
        "common": "Samoa",
        "official": "Independent State of Samoa",
        "nativeName": {
            "eng": {
                "official": "Independent State of Samoa",
                "common": "Samoa"
            },
            "smo": {
                "official": "Malo Saʻoloto Tutoʻatasi o Sāmoa",
                "common": "Sāmoa"
            }
        }
    },
    {
        "common": "Bolivia",
        "official": "Plurinational State of Bolivia",
        "nativeName": {
            "aym": {
                "official": "Wuliwya Suyu",
                "common": "Wuliwya"
            },
            "grn": {
                "official": "Tetã Volívia",
                "common": "Volívia"
            },
            "que": {
                "official": "Buliwya Mamallaqta",
                "common": "Buliwya"
            },
            "spa": {
                "official": "Estado Plurinacional de Bolivia",
                "common": "Bolivia"
            }
        }
    },
    {
        "common": "Madagascar",
        "official": "Republic of Madagascar",
        "nativeName": {
            "fra": {
                "official": "République de Madagascar",
                "common": "Madagascar"
            },
            "mlg": {
                "official": "Repoblikan'i Madagasikara",
                "common": "Madagasikara"
            }
        }
    },
    {
        "common": "São Tomé and Príncipe",
        "official": "Democratic Republic of São Tomé and Príncipe",
        "nativeName": {
            "por": {
                "official": "República Democrática do São Tomé e Príncipe",
                "common": "São Tomé e Príncipe"
            }
        }
    },
    {
        "common": "Bahrain",
        "official": "Kingdom of Bahrain",
        "nativeName": {
            "ara": {
                "official": "مملكة البحرين",
                "common": "‏البحرين"
            }
        }
    },
    {
        "common": "Greece",
        "official": "Hellenic Republic",
        "nativeName": {
            "ell": {
                "official": "Ελληνική Δημοκρατία",
                "common": "Ελλάδα"
            }
        }
    },
    {
        "common": "Peru",
        "official": "Republic of Peru",
        "nativeName": {
            "aym": {
                "official": "Piruw Suyu",
                "common": "Piruw"
            },
            "que": {
                "official": "Piruw Ripuwlika",
                "common": "Piruw"
            },
            "spa": {
                "official": "República del Perú",
                "common": "Perú"
            }
        }
    },
    {
        "common": "Suriname",
        "official": "Republic of Suriname",
        "nativeName": {
            "nld": {
                "official": "Republiek Suriname",
                "common": "Suriname"
            }
        }
    },
    {
        "common": "American Samoa",
        "official": "American Samoa",
        "nativeName": {
            "eng": {
                "official": "American Samoa",
                "common": "American Samoa"
            },
            "smo": {
                "official": "Sāmoa Amelika",
                "common": "Sāmoa Amelika"
            }
        }
    },
    {
        "common": "Niger",
        "official": "Republic of Niger",
        "nativeName": {
            "fra": {
                "official": "République du Niger",
                "common": "Niger"
            }
        }
    },
    {
        "common": "Thailand",
        "official": "Kingdom of Thailand",
        "nativeName": {
            "tha": {
                "official": "ราชอาณาจักรไทย",
                "common": "ประเทศไทย"
            }
        }
    },
    {
        "common": "Tuvalu",
        "official": "Tuvalu",
        "nativeName": {
            "eng": {
                "official": "Tuvalu",
                "common": "Tuvalu"
            },
            "tvl": {
                "official": "Tuvalu",
                "common": "Tuvalu"
            }
        }
    },
    {
        "common": "North Korea",
        "official": "Democratic People's Republic of Korea",
        "nativeName": {
            "kor": {
                "official": "조선민주주의인민공화국",
                "common": "조선"
            }
        }
    },
    {
        "common": "Ivory Coast",
        "official": "Republic of Côte d'Ivoire",
        "nativeName": {
            "fra": {
                "official": "République de Côte d'Ivoire",
                "common": "Côte d'Ivoire"
            }
        }
    },
    {
        "common": "Yemen",
        "official": "Republic of Yemen",
        "nativeName": {
            "ara": {
                "official": "الجمهورية اليمنية",
                "common": "اليَمَن"
            }
        }
    },
    {
        "common": "New Zealand",
        "official": "New Zealand",
        "nativeName": {
            "eng": {
                "official": "New Zealand",
                "common": "New Zealand"
            },
            "mri": {
                "official": "Aotearoa",
                "common": "Aotearoa"
            },
            "nzs": {
                "official": "New Zealand",
                "common": "New Zealand"
            }
        }
    },
    {
        "common": "Armenia",
        "official": "Republic of Armenia",
        "nativeName": {
            "hye": {
                "official": "Հայաստանի Հանրապետություն",
                "common": "Հայաստան"
            }
        }
    },
    {
        "common": "Pakistan",
        "official": "Islamic Republic of Pakistan",
        "nativeName": {
            "eng": {
                "official": "Islamic Republic of Pakistan",
                "common": "Pakistan"
            },
            "urd": {
                "official": "اسلامی جمہوریۂ پاكستان",
                "common": "پاكستان"
            }
        }
    },
    {
        "common": "Tokelau",
        "official": "Tokelau",
        "nativeName": {
            "eng": {
                "official": "Tokelau",
                "common": "Tokelau"
            },
            "smo": {
                "official": "Tokelau",
                "common": "Tokelau"
            },
            "tkl": {
                "official": "Tokelau",
                "common": "Tokelau"
            }
        }
    },
    {
        "common": "Malawi",
        "official": "Republic of Malawi",
        "nativeName": {
            "eng": {
                "official": "Republic of Malawi",
                "common": "Malawi"
            },
            "nya": {
                "official": "Chalo cha Malawi, Dziko la Malaŵi",
                "common": "Malaŵi"
            }
        }
    },
    {
        "common": "Ireland",
        "official": "Republic of Ireland",
        "nativeName": {
            "eng": {
                "official": "Republic of Ireland",
                "common": "Ireland"
            },
            "gle": {
                "official": "Poblacht na hÉireann",
                "common": "Éire"
            }
        }
    },
    {
        "common": "Philippines",
        "official": "Republic of the Philippines",
        "nativeName": {
            "eng": {
                "official": "Republic of the Philippines",
                "common": "Philippines"
            },
            "fil": {
                "official": "Republic of the Philippines",
                "common": "Pilipinas"
            }
        }
    },
    {
        "common": "Czechia",
        "official": "Czech Republic",
        "nativeName": {
            "ces": {
                "official": "Česká republika",
                "common": "Česko"
            },
            "slk": {
                "official": "Česká republika",
                "common": "Česko"
            }
        }
    },
    {
        "common": "Myanmar",
        "official": "Republic of the Union of Myanmar",
        "nativeName": {
            "mya": {
                "official": "ပြည်ထောင်စု သမ္မတ မြန်မာနိုင်ငံတော်",
                "common": "မြန်မာ"
            }
        }
    },
    {
        "common": "Cocos (Keeling) Islands",
        "official": "Territory of the Cocos (Keeling) Islands",
        "nativeName": {
            "eng": {
                "official": "Territory of the Cocos (Keeling) Islands",
                "common": "Cocos (Keeling) Islands"
            }
        }
    },
    {
        "common": "Romania",
        "official": "Romania",
        "nativeName": {
            "ron": {
                "official": "România",
                "common": "România"
            }
        }
    },
    {
        "common": "Dominican Republic",
        "official": "Dominican Republic",
        "nativeName": {
            "spa": {
                "official": "República Dominicana",
                "common": "República Dominicana"
            }
        }
    },
    {
        "common": "Equatorial Guinea",
        "official": "Republic of Equatorial Guinea",
        "nativeName": {
            "fra": {
                "official": "République de la Guinée Équatoriale",
                "common": "Guinée équatoriale"
            },
            "por": {
                "official": "República da Guiné Equatorial",
                "common": "Guiné Equatorial"
            },
            "spa": {
                "official": "República de Guinea Ecuatorial",
                "common": "Guinea Ecuatorial"
            }
        }
    },
    {
        "common": "Ghana",
        "official": "Republic of Ghana",
        "nativeName": {
            "eng": {
                "official": "Republic of Ghana",
                "common": "Ghana"
            }
        }
    },
    {
        "common": "Malta",
        "official": "Republic of Malta",
        "nativeName": {
            "eng": {
                "official": "Republic of Malta",
                "common": "Malta"
            },
            "mlt": {
                "official": "Repubblika ta ' Malta",
                "common": "Malta"
            }
        }
    },
    {
        "common": "Turkey",
        "official": "Republic of Turkey",
        "nativeName": {
            "tur": {
                "official": "Türkiye Cumhuriyeti",
                "common": "Türkiye"
            }
        }
    },
    {
        "common": "Egypt",
        "official": "Arab Republic of Egypt",
        "nativeName": {
            "ara": {
                "official": "جمهورية مصر العربية",
                "common": "مصر"
            }
        }
    },
    {
        "common": "Mozambique",
        "official": "Republic of Mozambique",
        "nativeName": {
            "por": {
                "official": "República de Moçambique",
                "common": "Moçambique"
            }
        }
    },
    {
        "common": "Mayotte",
        "official": "Department of Mayotte",
        "nativeName": {
            "fra": {
                "official": "Département de Mayotte",
                "common": "Mayotte"
            }
        }
    },
    {
        "common": "Belgium",
        "official": "Kingdom of Belgium",
        "nativeName": {
            "deu": {
                "official": "Königreich Belgien",
                "common": "Belgien"
            },
            "fra": {
                "official": "Royaume de Belgique",
                "common": "Belgique"
            },
            "nld": {
                "official": "Koninkrijk België",
                "common": "België"
            }
        }
    },
    {
        "common": "Slovenia",
        "official": "Republic of Slovenia",
        "nativeName": {
            "slv": {
                "official": "Republika Slovenija",
                "common": "Slovenija"
            }
        }
    },
    {
        "common": "Hungary",
        "official": "Hungary",
        "nativeName": {
            "hun": {
                "official": "Magyarország",
                "common": "Magyarország"
            }
        }
    },
    {
        "common": "United Arab Emirates",
        "official": "United Arab Emirates",
        "nativeName": {
            "ara": {
                "official": "الإمارات العربية المتحدة",
                "common": "دولة الإمارات العربية المتحدة"
            }
        }
    },
    {
        "common": "Albania",
        "official": "Republic of Albania",
        "nativeName": {
            "sqi": {
                "official": "Republika e Shqipërisë",
                "common": "Shqipëria"
            }
        }
    },
    {
        "common": "Heard Island and McDonald Islands",
        "official": "Heard Island and McDonald Islands",
        "nativeName": {
            "eng": {
                "official": "Heard Island and McDonald Islands",
                "common": "Heard Island and McDonald Islands"
            }
        }
    },
    {
        "common": "Fiji",
        "official": "Republic of Fiji",
        "nativeName": {
            "eng": {
                "official": "Republic of Fiji",
                "common": "Fiji"
            },
            "fij": {
                "official": "Matanitu Tugalala o Viti",
                "common": "Viti"
            },
            "hif": {
                "official": "रिपब्लिक ऑफ फीजी",
                "common": "फिजी"
            }
        }
    },
    {
        "common": "San Marino",
        "official": "Republic of San Marino",
        "nativeName": {
            "ita": {
                "official": "Repubblica di San Marino",
                "common": "San Marino"
            }
        }
    },
    {
        "common": "Moldova",
        "official": "Republic of Moldova",
        "nativeName": {
            "ron": {
                "official": "Republica Moldova",
                "common": "Moldova"
            }
        }
    },
    {
        "common": "Estonia",
        "official": "Republic of Estonia",
        "nativeName": {
            "est": {
                "official": "Eesti Vabariik",
                "common": "Eesti"
            }
        }
    },
    {
        "common": "Saint Vincent and the Grenadines",
        "official": "Saint Vincent and the Grenadines",
        "nativeName": {
            "eng": {
                "official": "Saint Vincent and the Grenadines",
                "common": "Saint Vincent and the Grenadines"
            }
        }
    },
    {
        "common": "United States Minor Outlying Islands",
        "official": "United States Minor Outlying Islands",
        "nativeName": {
            "eng": {
                "official": "United States Minor Outlying Islands",
                "common": "United States Minor Outlying Islands"
            }
        }
    },
    {
        "common": "Belarus",
        "official": "Republic of Belarus",
        "nativeName": {
            "bel": {
                "official": "Рэспубліка Беларусь",
                "common": "Белару́сь"
            },
            "rus": {
                "official": "Республика Беларусь",
                "common": "Беларусь"
            }
        }
    },
    {
        "common": "Sri Lanka",
        "official": "Democratic Socialist Republic of Sri Lanka",
        "nativeName": {
            "sin": {
                "official": "ශ්‍රී ලංකා ප්‍රජාතාන්ත්‍රික සමාජවාදී ජනරජය",
                "common": "ශ්‍රී ලංකාව"
            },
            "tam": {
                "official": "இலங்கை சனநாயக சோசலிசக் குடியரசு",
                "common": "இலங்கை"
            }
        }
    },
    {
        "common": "El Salvador",
        "official": "Republic of El Salvador",
        "nativeName": {
            "spa": {
                "official": "República de El Salvador",
                "common": "El Salvador"
            }
        }
    },
    {
        "common": "Australia",
        "official": "Commonwealth of Australia",
        "nativeName": {
            "eng": {
                "official": "Commonwealth of Australia",
                "common": "Australia"
            }
        }
    },
    {
        "common": "Bermuda",
        "official": "Bermuda",
        "nativeName": {
            "eng": {
                "official": "Bermuda",
                "common": "Bermuda"
            }
        }
    },
    {
        "common": "Nicaragua",
        "official": "Republic of Nicaragua",
        "nativeName": {
            "spa": {
                "official": "República de Nicaragua",
                "common": "Nicaragua"
            }
        }
    },
    {
        "common": "Somalia",
        "official": "Federal Republic of Somalia",
        "nativeName": {
            "ara": {
                "official": "جمهورية الصومال‎‎",
                "common": "الصومال‎‎"
            },
            "som": {
                "official": "Jamhuuriyadda Federaalka Soomaaliya",
                "common": "Soomaaliya"
            }
        }
    },
    {
        "common": "Turks and Caicos Islands",
        "official": "Turks and Caicos Islands",
        "nativeName": {
            "eng": {
                "official": "Turks and Caicos Islands",
                "common": "Turks and Caicos Islands"
            }
        }
    },
    {
        "common": "Micronesia",
        "official": "Federated States of Micronesia",
        "nativeName": {
            "eng": {
                "official": "Federated States of Micronesia",
                "common": "Micronesia"
            }
        }
    },
    {
        "common": "Turkmenistan",
        "official": "Turkmenistan",
        "nativeName": {
            "rus": {
                "official": "Туркменистан",
                "common": "Туркмения"
            },
            "tuk": {
                "official": "Türkmenistan",
                "common": "Türkmenistan"
            }
        }
    },
    {
        "common": "Burkina Faso",
        "official": "Burkina Faso",
        "nativeName": {
            "fra": {
                "official": "République du Burkina",
                "common": "Burkina Faso"
            }
        }
    },
    {
        "common": "Costa Rica",
        "official": "Republic of Costa Rica",
        "nativeName": {
            "spa": {
                "official": "República de Costa Rica",
                "common": "Costa Rica"
            }
        }
    },
    {
        "common": "Vietnam",
        "official": "Socialist Republic of Vietnam",
        "nativeName": {
            "vie": {
                "official": "Cộng hòa xã hội chủ nghĩa Việt Nam",
                "common": "Việt Nam"
            }
        }
    },
    {
        "common": "Cameroon",
        "official": "Republic of Cameroon",
        "nativeName": {
            "eng": {
                "official": "Republic of Cameroon",
                "common": "Cameroon"
            },
            "fra": {
                "official": "République du Cameroun",
                "common": "Cameroun"
            }
        }
    },
    {
        "common": "French Guiana",
        "official": "Guiana",
        "nativeName": {
            "fra": {
                "official": "Guyane",
                "common": "Guyane française"
            }
        }
    },
    {
        "common": "Eswatini",
        "official": "Kingdom of Eswatini",
        "nativeName": {
            "eng": {
                "official": "Kingdom of Eswatini",
                "common": "Eswatini"
            },
            "ssw": {
                "official": "Umbuso weSwatini",
                "common": "eSwatini"
            }
        }
    },
    {
        "common": "Zambia",
        "official": "Republic of Zambia",
        "nativeName": {
            "eng": {
                "official": "Republic of Zambia",
                "common": "Zambia"
            }
        }
    },
    {
        "common": "Liechtenstein",
        "official": "Principality of Liechtenstein",
        "nativeName": {
            "deu": {
                "official": "Fürstentum Liechtenstein",
                "common": "Liechtenstein"
            }
        }
    },
    {
        "common": "Montenegro",
        "official": "Montenegro",
        "nativeName": {
            "cnr": {
                "official": "Црна Гора",
                "common": "Црна Гора"
            }
        }
    },
    {
        "common": "Saint Lucia",
        "official": "Saint Lucia",
        "nativeName": {
            "eng": {
                "official": "Saint Lucia",
                "common": "Saint Lucia"
            }
        }
    },
    {
        "common": "Uzbekistan",
        "official": "Republic of Uzbekistan",
        "nativeName": {
            "rus": {
                "official": "Республика Узбекистан",
                "common": "Узбекистан"
            },
            "uzb": {
                "official": "O'zbekiston Respublikasi",
                "common": "O‘zbekiston"
            }
        }
    },
    {
        "common": "Chad",
        "official": "Republic of Chad",
        "nativeName": {
            "ara": {
                "official": "جمهورية تشاد",
                "common": "تشاد‎"
            },
            "fra": {
                "official": "République du Tchad",
                "common": "Tchad"
            }
        }
    },
    {
        "common": "Aruba",
        "official": "Aruba",
        "nativeName": {
            "nld": {
                "official": "Aruba",
                "common": "Aruba"
            },
            "pap": {
                "official": "Aruba",
                "common": "Aruba"
            }
        }
    },
    {
        "common": "Denmark",
        "official": "Kingdom of Denmark",
        "nativeName": {
            "dan": {
                "official": "Kongeriget Danmark",
                "common": "Danmark"
            }
        }
    },
    {
        "common": "Japan",
        "official": "Japan",
        "nativeName": {
            "jpn": {
                "official": "日本",
                "common": "日本"
            }
        }
    },
    {
        "common": "Cape Verde",
        "official": "Republic of Cabo Verde",
        "nativeName": {
            "por": {
                "official": "República de Cabo Verde",
                "common": "Cabo Verde"
            }
        }
    },
    {
        "common": "Switzerland",
        "official": "Swiss Confederation",
        "nativeName": {
            "fra": {
                "official": "Confédération suisse",
                "common": "Suisse"
            },
            "gsw": {
                "official": "Schweizerische Eidgenossenschaft",
                "common": "Schweiz"
            },
            "ita": {
                "official": "Confederazione Svizzera",
                "common": "Svizzera"
            },
            "roh": {
                "official": "Confederaziun svizra",
                "common": "Svizra"
            }
        }
    },
    {
        "common": "Hong Kong",
        "official": "Hong Kong Special Administrative Region of the People's Republic of China",
        "nativeName": {
            "eng": {
                "official": "Hong Kong Special Administrative Region of the People's Republic of China",
                "common": "Hong Kong"
            },
            "zho": {
                "official": "中华人民共和国香港特别行政区",
                "common": "香港"
            }
        }
    },
    {
        "common": "Bulgaria",
        "official": "Republic of Bulgaria",
        "nativeName": {
            "bul": {
                "official": "Република България",
                "common": "България"
            }
        }
    },
    {
        "common": "Jordan",
        "official": "Hashemite Kingdom of Jordan",
        "nativeName": {
            "ara": {
                "official": "المملكة الأردنية الهاشمية",
                "common": "الأردن"
            }
        }
    },
    {
        "common": "Republic of the Congo",
        "official": "Republic of the Congo",
        "nativeName": {
            "fra": {
                "official": "République du Congo",
                "common": "République du Congo"
            },
            "kon": {
                "official": "Repubilika ya Kongo",
                "common": "Repubilika ya Kongo"
            },
            "lin": {
                "official": "Republíki ya Kongó",
                "common": "Republíki ya Kongó"
            }
        }
    },
    {
        "common": "Laos",
        "official": "Lao People's Democratic Republic",
        "nativeName": {
            "lao": {
                "official": "ສາທາລະນະ ຊາທິປະໄຕ ຄົນລາວ ຂອງ",
                "common": "ສປປລາວ"
            }
        }
    },
    {
        "common": "Norfolk Island",
        "official": "Territory of Norfolk Island",
        "nativeName": {
            "eng": {
                "official": "Territory of Norfolk Island",
                "common": "Norfolk Island"
            },
            "pih": {
                "official": "Teratri of Norf'k Ailen",
                "common": "Norf'k Ailen"
            }
        }
    },
    {
        "common": "South Korea",
        "official": "Republic of Korea",
        "nativeName": {
            "kor": {
                "official": "대한민국",
                "common": "한국"
            }
        }
    },
    {
        "common": "Tunisia",
        "official": "Tunisian Republic",
        "nativeName": {
            "ara": {
                "official": "الجمهورية التونسية",
                "common": "تونس"
            }
        }
    },
    {
        "common": "Finland",
        "official": "Republic of Finland",
        "nativeName": {
            "fin": {
                "official": "Suomen tasavalta",
                "common": "Suomi"
            },
            "swe": {
                "official": "Republiken Finland",
                "common": "Finland"
            }
        }
    },
    {
        "common": "Zimbabwe",
        "official": "Republic of Zimbabwe",
        "nativeName": {
            "bwg": {
                "official": "Republic of Zimbabwe",
                "common": "Zimbabwe"
            },
            "eng": {
                "official": "Republic of Zimbabwe",
                "common": "Zimbabwe"
            },
            "kck": {
                "official": "Republic of Zimbabwe",
                "common": "Zimbabwe"
            },
            "khi": {
                "official": "Republic of Zimbabwe",
                "common": "Zimbabwe"
            },
            "ndc": {
                "official": "Republic of Zimbabwe",
                "common": "Zimbabwe"
            },
            "nde": {
                "official": "Republic of Zimbabwe",
                "common": "Zimbabwe"
            },
            "nya": {
                "official": "Republic of Zimbabwe",
                "common": "Zimbabwe"
            },
            "sna": {
                "official": "Republic of Zimbabwe",
                "common": "Zimbabwe"
            },
            "sot": {
                "official": "Republic of Zimbabwe",
                "common": "Zimbabwe"
            },
            "toi": {
                "official": "Republic of Zimbabwe",
                "common": "Zimbabwe"
            },
            "tsn": {
                "official": "Republic of Zimbabwe",
                "common": "Zimbabwe"
            },
            "tso": {
                "official": "Republic of Zimbabwe",
                "common": "Zimbabwe"
            },
            "ven": {
                "official": "Republic of Zimbabwe",
                "common": "Zimbabwe"
            },
            "xho": {
                "official": "Republic of Zimbabwe",
                "common": "Zimbabwe"
            },
            "zib": {
                "official": "Republic of Zimbabwe",
                "common": "Zimbabwe"
            }
        }
    },
    {
        "common": "Maldives",
        "official": "Republic of the Maldives",
        "nativeName": {
            "div": {
                "official": "ދިވެހިރާއްޖޭގެ ޖުމްހޫރިއްޔާ",
                "common": "ދިވެހިރާއްޖޭގެ"
            }
        }
    },
    {
        "common": "Singapore",
        "official": "Republic of Singapore",
        "nativeName": {
            "zho": {
                "official": "新加坡共和国",
                "common": "新加坡"
            },
            "eng": {
                "official": "Republic of Singapore",
                "common": "Singapore"
            },
            "msa": {
                "official": "Republik Singapura",
                "common": "Singapura"
            },
            "tam": {
                "official": "சிங்கப்பூர் குடியரசு",
                "common": "சிங்கப்பூர்"
            }
        }
    },
    {
        "common": "Monaco",
        "official": "Principality of Monaco",
        "nativeName": {
            "fra": {
                "official": "Principauté de Monaco",
                "common": "Monaco"
            }
        }
    },
    {
        "common": "Angola",
        "official": "Republic of Angola",
        "nativeName": {
            "por": {
                "official": "República de Angola",
                "common": "Angola"
            }
        }
    },
    {
        "common": "Malaysia",
        "official": "Malaysia",
        "nativeName": {
            "eng": {
                "official": "Malaysia",
                "common": "Malaysia"
            },
            "msa": {
                "official": "مليسيا",
                "common": "مليسيا"
            }
        }
    },
    {
        "common": "Luxembourg",
        "official": "Grand Duchy of Luxembourg",
        "nativeName": {
            "deu": {
                "official": "Großherzogtum Luxemburg",
                "common": "Luxemburg"
            },
            "fra": {
                "official": "Grand-Duché de Luxembourg",
                "common": "Luxembourg"
            },
            "ltz": {
                "official": "Groussherzogtum Lëtzebuerg",
                "common": "Lëtzebuerg"
            }
        }
    },
    {
        "common": "Guinea",
        "official": "Republic of Guinea",
        "nativeName": {
            "fra": {
                "official": "République de Guinée",
                "common": "Guinée"
            }
        }
    },
    {
        "common": "Libya",
        "official": "State of Libya",
        "nativeName": {
            "ara": {
                "official": "الدولة ليبيا",
                "common": "‏ليبيا"
            }
        }
    },
    {
        "common": "Spain",
        "official": "Kingdom of Spain",
        "nativeName": {
            "spa": {
                "official": "Reino de España",
                "common": "España"
            }
        }
    },
    {
        "common": "Indonesia",
        "official": "Republic of Indonesia",
        "nativeName": {
            "ind": {
                "official": "Republik Indonesia",
                "common": "Indonesia"
            }
        }
    },
    {
        "common": "Seychelles",
        "official": "Republic of Seychelles",
        "nativeName": {
            "crs": {
                "official": "Repiblik Sesel",
                "common": "Sesel"
            },
            "eng": {
                "official": "Republic of Seychelles",
                "common": "Seychelles"
            },
            "fra": {
                "official": "République des Seychelles",
                "common": "Seychelles"
            }
        }
    },
    {
        "common": "Brunei",
        "official": "Nation of Brunei, Abode of Peace",
        "nativeName": {
            "msa": {
                "official": "Nation of Brunei, Abode Damai",
                "common": "Negara Brunei Darussalam"
            }
        }
    },
    {
        "common": "Mexico",
        "official": "United Mexican States",
        "nativeName": {
            "spa": {
                "official": "Estados Unidos Mexicanos",
                "common": "México"
            }
        }
    },
    {
        "common": "Saint Helena, Ascension and Tristan da Cunha",
        "official": "Saint Helena, Ascension and Tristan da Cunha",
        "nativeName": {
            "eng": {
                "official": "Saint Helena, Ascension and Tristan da Cunha",
                "common": "Saint Helena, Ascension and Tristan da Cunha"
            }
        }
    },
    {
        "common": "Sierra Leone",
        "official": "Republic of Sierra Leone",
        "nativeName": {
            "eng": {
                "official": "Republic of Sierra Leone",
                "common": "Sierra Leone"
            }
        }
    },
    {
        "common": "Central African Republic",
        "official": "Central African Republic",
        "nativeName": {
            "fra": {
                "official": "République centrafricaine",
                "common": "République centrafricaine"
            },
            "sag": {
                "official": "Ködörösêse tî Bêafrîka",
                "common": "Bêafrîka"
            }
        }
    },
    {
        "common": "Greenland",
        "official": "Greenland",
        "nativeName": {
            "kal": {
                "official": "Kalaallit Nunaat",
                "common": "Kalaallit Nunaat"
            }
        }
    },
    {
        "common": "Colombia",
        "official": "Republic of Colombia",
        "nativeName": {
            "spa": {
                "official": "República de Colombia",
                "common": "Colombia"
            }
        }
    },
    {
        "common": "Papua New Guinea",
        "official": "Independent State of Papua New Guinea",
        "nativeName": {
            "eng": {
                "official": "Independent State of Papua New Guinea",
                "common": "Papua New Guinea"
            },
            "hmo": {
                "official": "Independen Stet bilong Papua Niugini",
                "common": "Papua Niu Gini"
            },
            "tpi": {
                "official": "Independen Stet bilong Papua Niugini",
                "common": "Papua Niugini"
            }
        }
    },
    {
        "common": "Falkland Islands",
        "official": "Falkland Islands",
        "nativeName": {
            "eng": {
                "official": "Falkland Islands",
                "common": "Falkland Islands"
            }
        }
    },
    {
        "common": "Portugal",
        "official": "Portuguese Republic",
        "nativeName": {
            "por": {
                "official": "República português",
                "common": "Portugal"
            }
        }
    },
    {
        "common": "Guinea-Bissau",
        "official": "Republic of Guinea-Bissau",
        "nativeName": {
            "por": {
                "official": "República da Guiné-Bissau",
                "common": "Guiné-Bissau"
            },
            "pov": {
                "official": "República da Guiné-Bissau",
                "common": "Guiné-Bissau"
            }
        }
    },
    {
        "common": "Saint Martin",
        "official": "Saint Martin",
        "nativeName": {
            "fra": {
                "official": "Saint-Martin",
                "common": "Saint-Martin"
            }
        }
    },
    {
        "common": "Tanzania",
        "official": "United Republic of Tanzania",
        "nativeName": {
            "eng": {
                "official": "United Republic of Tanzania",
                "common": "Tanzania"
            },
            "swa": {
                "official": "Jamhuri ya Muungano wa Tanzania",
                "common": "Tanzania"
            }
        }
    }
]
export default Countries