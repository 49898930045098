import "./Message.css";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import moment from "moment";
const Message = (props) => {
  useEffect(() => {
    const success = (position) => {
      console.log(position);
    };
    const error = (position) => {
      console.error(position);
    };
    // console.log(navigator.geolocation.getCurrentPosition(success, error));
  }, []);
  return (
    <div
      className={`message-container ${props.question ? "bot" : "client"} ${
        props.generated && "generated"
      }`}
    >
      <div
        // style={{
        //   filter:
        //     props.Messages[minus1()].question == props.question
        //       ? "opacity(0)"
        //       : "none",
        // }}
        className="avatar"
      />

      <div data-date={moment(props.date).format("HH:mm")} className="message">
        {props.content}
      </div>
    </div>
  );
};

export default Message;
