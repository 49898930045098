import { useEffect } from 'react';
import { useState } from 'react';
import MainView from './components/MainView/MainView';
import './index.css'
import hacking from './media/audio/hacking.mp3'
function App() {
  const [Error, setError] = useState(false)
  const sound = new Audio(hacking)

  useEffect(() => {
    if (Error) {
      sound.play()
    }
  }, [Error])

  return (
    <div className={`App ${Error ? " error" : ""}`}>
      <MainView Error={Error} setError={setError}></MainView>
    </div>
  );
}

export default App;
